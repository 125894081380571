import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { XB_Button } from '@core-components/atoms';
import { XB_InlineMessage, XB_InputOtp } from '@core-components/molecules';
import { appConstants, OTPJourneyType } from '@core-constants';
import { useCountdown } from '@core-hooks';
import {
  OTPSend,
  OTPValidate,
  sendOtp,
  ServerError,
  validateOtp,
} from '@core-services';
import { doAadhaarService } from '@src/modules/common/services/user-management.service';

import './XB_VerifyOtp.style.scss';

interface VerifyOtpProps {
  id: string;
  otpId: string;
  otpLabel: string;
  mobileNumber: string;
  email?: string;
  customerId?: string;
  resendTimer: number;
  expiryTimer: number;
  otpJourney?: OTPJourneyType;
  resendText?: string;
  onOtpVerified: () => void;
  onOtpError: (err: string, message: string) => void;
  onResendOtp?: (
    num: string,
    id: string,
    resendTimer: number,
    expiryTimer: number,
    email?: string,
    remainingCounts?: number
  ) => void;
  autofocus?: boolean;
  isAadharOTP?: boolean;
  aadhaarNumber?: string;
  onOtpSuccess?: (resp: any) => void;
  height?: 'auto' | 'min-height';
  otpLength?: number;
  otpSeparatorLength?: number;
  aadhaarOTPTxnID?: string;
}

export const XB_VerifyOtp = ({
  id,
  otpId,
  otpLabel,
  mobileNumber,
  email,
  customerId,
  resendTimer,
  expiryTimer,
  onOtpVerified,
  onOtpError,
  onResendOtp,
  resendText = 'resendCode',
  otpJourney = OTPJourneyType.PRE_REGISTRATION,
  autofocus = true,
  isAadharOTP = false,
  aadhaarNumber,
  onOtpSuccess,
  height = 'min-height',
  otpLength = 6,
  otpSeparatorLength = 3,
  aadhaarOTPTxnID,
}: VerifyOtpProps) => {
  const { t } = useTranslation();
  const [OTP, setOTP] = useState('');
  const [OTPStatus, setOTPStatus] = useState<'error' | 'success' | 'info'>(
    'info'
  );
  const [infoText, setInfoText] = useState<string | React.ReactNode>('');
  const [isEditable, setIsEditable] = useState(true);
  const [resendAttempts, setResendAttempts] = useState(3);
  const [onCall, setOnCall] = useState(false);
  const [verified, setVerified] = useState(false);
  const [resendCountdown, resetResendCountdown] = useCountdown(
    Number(resendTimer)
  );
  const [expireCountdown, resetExpireCountdown] = useCountdown(
    Number(expiryTimer)
  );
  const [isResendBtnDisabled, setResendBtnDisabled] = useState<boolean>(false);

  const prevOtpRef = useRef<string>('');

  const sendAadhaarVerifyOTP = () => {
    const data = {
      mobileNumber,
      aadhaarNumber,
      channelCode: appConstants.APP_PLATFORM,
      channelVersion: '2.0.4',
    };
    void (async () => {
      try {
        const response = await doAadhaarService(data);
        if (response.status === 'success') {
          onResendOtp?.(mobileNumber, response, resendTimer, expiryTimer);
          resetResendCountdown(Number(resendTimer));
          resetExpireCountdown(Number(expiryTimer));
          setInfoText('');
        }
      } catch (err: any) {}
    })();
  };
  const resendOTP = () => {
    void (async () => {
      const otpObject: OTPSend = {
        mobileNumber,
      };
      if (email) {
        customerId && (otpObject.customerId = customerId);
        otpObject.emailAddress = email;
      }
      if (isAadharOTP) {
        sendAadhaarVerifyOTP();
      } else {
        const response = await sendOtp(otpObject, otpJourney);
        if (response.status === 'success') {
          const { otpResendSeconds, otpExpiredSeconds } = response.data;
          setOTP('');
          setInfoText('');
          resetResendCountdown(Number(otpResendSeconds));
          resetExpireCountdown(Number(otpExpiredSeconds));
          setResendAttempts((prevVal) => prevVal - 1);
          onResendOtp?.(
            mobileNumber,
            response.data.otpId,
            otpResendSeconds,
            otpExpiredSeconds,
            email
          );
        }
        if (response.status === 'error') {
          setInfoText(response.error.message);
          setOTPStatus('error');
        }
      }
    })();
  };

  const checkOTPValidity = useCallback(
    (validFlag, attemptsLeft, userLockMinutes) => {
      if (validFlag === 'y') {
        setOnCall(true);
        setOTPStatus('success');
        setInfoText(t('verified'));
        setVerified(true);
        onOtpVerified();
      } else if (validFlag === 'n') {
        setOnCall(true);
        setOTPStatus('error');
        setIsEditable(true);
        if (attemptsLeft > 0) {
          setInfoText(`Invalid OTP, ${Number(attemptsLeft)} attempt left`);
        } else if (userLockMinutes) {
          setResendBtnDisabled(true);
          setInfoText('');
          onOtpError(
            t('toManyFailedAttempts'),
            t('lockMessage', { count: userLockMinutes })
          );
        } else {
          setResendBtnDisabled(true);
          setInfoText(t('maxOtpAttempts'));
          onOtpError('', t('maxOtpAttempts'));
        }
      }
    },
    [onOtpError, onOtpVerified, t]
  );
  const getAadharOTP = () => {
    void (async () => {
      const data = {
        mobileNumber,
        aadhaarNumber,
        channelCode: appConstants.APP_PLATFORM,
        channelVersion: '2.0.4',
        aadhaarOtp: OTP,
        txnId: aadhaarOTPTxnID,
      };

      try {
        const response = await doAadhaarService(data);
        if (response.status === 'success') {
          const { otpAttemptsLeft } = response.data.content;

          if (response.data.message === 'Invalid aadhaar OTP') {
            setOTPStatus('error');
            setIsEditable(true);
            if (otpAttemptsLeft > 0) {
              setInfoText(
                `Invalid OTP, ${Number(otpAttemptsLeft)} attempt left`
              );
            } else if (otpAttemptsLeft === 0) {
              setInfoText(t('toManyFailedAttempts'));
            }
          } else {
            setInfoText('');
            onOtpSuccess?.(response);
          }
        }
        if (response.status === 'error') {
          setOnCall(true);
          setOTPStatus('error');
          setIsEditable(true);
          setInfoText(response.error.message);
        }
      } catch (err) {
        setOnCall(true);
        setOTPStatus('error');
        setIsEditable(true);
        setInfoText((err as ServerError).getErrorMessage());
      }
    })();
  };
  const getOTPApiCall = async (otpObject) => {
    try {
      const response = await validateOtp(otpObject, otpJourney);

      if (response?.status === 'success') {
        const { validFlag, attemptsLeft, userLockMinutes } = response.data;
        checkOTPValidity(validFlag, attemptsLeft, userLockMinutes);
      }
      if (response.status === 'error') {
        setOnCall(true);
        setOTPStatus('error');
        setIsEditable(true);
        if (response?.error?.errorCode === 'USR1001') {
          setInfoText(t('otpExpired'));
        } else if (response?.error?.errorCode === 'UMS0443') {
          setInfoText(t('UMS0443'));
        } else if (response?.error?.errorCode === 'UMS0400') {
          setInfoText(response?.error?.message);
          setResendBtnDisabled(true);
        } else {
          setInfoText(response?.error?.message);
        }
      }
    } catch (err: any) {
      setOnCall(true);
      setOTPStatus('error');
      setIsEditable(true);
      if (err?.bucketMessage?.includes('USR0423')) {
        setInfoText(t('toManyFailedAttempts'));
      } else {
        setInfoText(err.displayMessage);
      }
    }
  };
  const getOTP = () => {
    const otpObject: OTPValidate = {
      otpId,
      otpValue: OTP,
    };
    if (email) {
      otpObject.emailAddress = email;
      if (otpJourney === 'ONBOARDING') {
        otpObject.mobileNumber = mobileNumber;
      }
    } else {
      otpObject.mobileNumber = mobileNumber;
    }
    getOTPApiCall(otpObject);
  };
  const validateEnteredOTP = useCallback(() => {
    setOTPStatus('info');
    setInfoText(
      <span className="flex gap-x-2">
        <span className="loader"></span>
        <span className="loader-text">{t('verifying')}</span>
      </span>
    );
    setOnCall(true);
    setIsEditable(false);
    if (isAadharOTP) {
      getAadharOTP();
    } else {
      getOTP();
    }
  }, [t, otpId, OTP, email, otpJourney, mobileNumber, checkOTPValidity]);

  useEffect(() => {
    if (OTP?.length > 6 || OTP?.length < 6 || prevOtpRef?.current !== OTP) {
      setInfoText('');
      setOTPStatus('info');
      setOnCall(false);
    }
  }, [OTP]);

  useEffect(() => {
    prevOtpRef.current = OTP;
  }, [OTP]);

  useEffect(() => {
    if (!expireCountdown && !infoText && resendAttempts > 0) {
      setOTPStatus('info');
      setInfoText('Please generate new OTP via RESEND OTP');
    }
  }, [infoText, expireCountdown]);

  useEffect(() => {
    if (OTP?.length === 6 && !onCall) {
      setOnCall(true);
      validateEnteredOTP();
    }
  }, [OTP, onCall, validateEnteredOTP]);
  return (
    <div className={`otp-input__box ${height}`}>
      <XB_InputOtp
        id={id}
        label={otpLabel}
        length={otpLength}
        onValueChange={setOTP}
        separator={otpSeparatorLength}
        value={OTP}
        textType={OTPStatus}
        additionalText={infoText}
        disabled={!isEditable}
        autoFocus={autofocus}
      />
      {!verified && resendAttempts > 0 && (
        <div className={`flex items-center mt-2`}>
          <XB_Button
            dataTestId="RSD_OTP"
            onClick={resendOTP}
            btnType="tertiary"
            size="lg"
            classes={`resend-otp-btn`}
            disabled={resendCountdown !== 0 || isResendBtnDisabled}
            type="button"
          >
            {t(resendText)}
          </XB_Button>
          {resendCountdown > 0 && (
            <span className="text-sm font-medium ml-2 text-darkgray">
              {t('in')} 00:
              {resendCountdown < 10 ? `0${resendCountdown}` : resendCountdown}
            </span>
          )}
        </div>
      )}
      {resendAttempts <= 0 && (
        <XB_InlineMessage
          title={t('resendOtpAttemptsExhausted')}
          message={t('')}
          icon="info-circle"
          type="info"
          size="lg"
          classes="mt-4"
        />
      )}
    </div>
  );
};
