/* IMPORTANT -- All words should be in small letters */
const words = ['mental', 'fraud'];
const restrictNamesArr = [
  'na',
  'notapplicable',
  'abc',
  'xyz',
  'not applicable',
];

export const badWordCatch = (text: string): number => {
  const arr = text.toLowerCase().split(' ');
  const foundWords = arr.filter((el) => words.includes(el));
  return foundWords.length;
};
export const restrictNames = (text: string): boolean => {
  return restrictNamesArr.includes(text.toLowerCase());
};

export const findWords = (text: string, arr: any, keyName: string) => {
  return arr.some((tw) => tw[keyName].toLowerCase() === text.toLowerCase());
};

export const restrictDomains = (text: string, arr: any, keyName: string) => {
  return arr?.some((tw) =>
    text.toLowerCase().includes(tw[keyName].toLowerCase())
  );
};
