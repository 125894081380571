import { createSlice } from '@reduxjs/toolkit';
import { GenericResponse } from '@src/core-stores';

import { IResUsersDetailsItem, SettlementData } from '../../models';
import {
  ICustomerAccount,
  IMerchantData,
} from '../../models/merchant-manegement.model';
import { apifetchMerchantDetails } from '../thunks/merchant-management.thunk';

export interface MechantManagement extends GenericResponse {
  data?: IMerchantData;
  auAccounts?: ICustomerAccount[];
  settlementData?: SettlementData[];
  userIdsDetails?: IResUsersDetailsItem[];
}

const initialState: MechantManagement = {
  status: undefined,
  loading: false,
  successfulResponse: undefined,
  error: undefined,
  data: undefined,
  auAccounts: [],
  settlementData: [],
  userIdsDetails: [],
};

const merchantManagementSlice = createSlice({
  name: 'merchantManagement',
  initialState,
  reducers: {
    auAccountsReducer(state, { payload }) {
      state.auAccounts = payload;
    },

    settlementCyclesReducer(state, { payload }) {
      state.settlementData = payload;
    },

    resetMerchantDetailsState(state) {
      state.auAccounts = [];
      state.userIdsDetails = [];
      state.data = undefined;
    },

    setUserIdsDetails(state, { payload }) {
      state.userIdsDetails = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(apifetchMerchantDetails.pending, (state) => {
      state.status = undefined;
      state.error = undefined;
      state.loading = true;
      state.successfulResponse = undefined;
      state.data = undefined;
    });
    builder.addCase(
      apifetchMerchantDetails.fulfilled,
      (state, { payload }: { payload: any }) => {
        ({
          status: state.status,
          error: state.error,
          successfulResponse: state.successfulResponse,
          data: state.data,
        } = payload);
        state.loading = false;
      }
    );
    builder.addCase(
      apifetchMerchantDetails.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = undefined;
        state.data = undefined;
        state.loading = false;
      }
    );
  },
});

export const {
  auAccountsReducer,
  settlementCyclesReducer,
  resetMerchantDetailsState,
  setUserIdsDetails,
} = merchantManagementSlice.actions;
export default merchantManagementSlice.reducer;
