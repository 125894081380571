import { t } from 'i18next';

import { Items } from '@core-constants';

export const MODULE_NAME = 'entitlement';
export const MODULE_NAME_LIMIT = 'limit';
export const MODULE_NAME_SETTLEMENT = 'settlement';
export const MODULE_NAME_APP_CONFIGURATION = 'app-configuration';

export enum appIds {
  MERCHANT_APP = 'MERCHANT_APP',
  EMPLOYEE_APP = 'EMPLOYEE_APP',
}
export const addFeatureTableColumn = [
  { class: 'w-1/5', label: 'serialNumber' },
  { class: 'w-3/5', label: 'featureName' },
  { class: 'w-1/5', label: 'actions' },
];

export const mockTableColumns = [
  {
    name: t('serialNo'),
    key: 'sNo',
    width: '4.5rem',
  },
  {
    name: t('featureName'),
    key: 'featureName',
    width: '16.75rem',
  },

  {
    name: t('permissionName'),
    key: 'permissionName',
    width: '40.18rem',
  },
];

export const BankSuffix = '@aubank.in';

export const configEntitle = {
  maxRoleName: 35,
  maxDescription: 500,
  defaultExpiryYear: 9999,
  minCifId: 8,
  maxCifId: 8,
  maxUserName: 40,
  minUserName: 3,
  dateUpTo: 15,
  dateStartFrom: 1,
  staticCif: '0',
  casaRelation: 'SOW',
  minMobileNo: 0,
  cifType: 'IND',
};
export const readWritePermissions = [
  'admin.role.add',
  'admin.role.assign',
  'admin.role.delete',
  'admin.role.remove',
  'admin.role.unassign',
  'admin.role.update',
  'admin.role.view',
];

export const roleListModalTypes = {
  blockWarning: 'blockWarning',
  logoutWarning: 'logoutWarning',
  blockNotAllowed: 'blockNotAllowed',
  unblockWarning: 'unblockWarning',
  downloadingReport: 'downloadingReport',
  disableWarning: 'disableWarning',
  disableNotAllowed: 'disableNotAllowed',
  delinkWarning: 'delinkWarning',
};

export const filterStatusType = [
  { label: 'active', id: '' },
  { label: 'inactive', id: '' },
  { label: 'pendingAuthorization', id: '' },
  { label: 'disabled', id: '' },
  { label: 'blocked', id: '' },
];

export const filterConstitutionType = [
  { label: 'Individual', id: '', value: 'IND' },
  { label: 'Sole Prop', id: '', value: 'SOLPROP' },
];

export const filterUserCount = ['0-5', '06-10', '11-20', '20+'];

export const ERR_CODES = {
  ROLE_EXISTS: 'UMS0209',
  PERMISSION_EXISTS: 'UMS0415',
  AUTH0412: 'AUTH0412',
};

export const TEXT_SEARCH_TYPE_ITEMS = [
  { text: 'CIF ID', value: 'CIF' },
  { text: 'Mobile No', value: 'MOBILE' },
  { text: 'Email ID', value: 'EMAIL' },
];

export const MR_SEARCH_TYPE_ITEMS = [
  { text: 'Mobile No', value: 'MOBILE' },
  { text: 'CIF ID', value: 'CIF' },
  { text: 'Name', value: 'USER_NAME' },
  { text: 'Email ID', value: 'EMAIL' },
  { text: 'QR Activation Date', value: 'QR ACTIVATION DATE' },
  { text: 'VPA ID', value: 'VPAID' },
];

export const MR_ROLE_TEXT_SEARCH_TYPES = {
  CIF: 'CIF',
  MOBILE: 'MOBILE',
  EMAIL: 'EMAIL',
  NAME: 'USER_NAME',
  VPA: 'VPA_ID',
  QR_DATE: 'QR ACTIVATION DATE',
  QR_DATE_API_PARAMS: 'VPA_DATE',
};
export const allPermissionList: string[] = [
  'user.group.add',
  'user.employee.view',
  'user.group.view',
  'user.group.update',
  'employee.authgroup.add',
  'employee.authgroup.listview',
  'employee.authgroup.update',
  'user.group.delete',
  'employee.authgroup.remove',
  'user.group.detail.list',
  'user.group.details.view',
];

export const totalUserRange = [
  { key: '0-5', start: 0, end: 5 },
  { key: '6-10', start: 6, end: 10 },
  { key: '11-40', start: 11, end: 40 },
  { key: '40+', start: 41, end: 10000 },
];

export enum RootActions {
  loading = 'set/loading',
  toggleFilter = 'toggle/filter',
  setRootData = 'table/data',
  setActiveList = 'active-group-list',
  toggelModal = 'modal/toggle',
  setModalData = 'set/Modal',
  setSelectedGroup = 'select/group',
  blockUnblockAction = 'block-unblock/group',
  setPemissions = 'set/permissions',
  applyFilterAction = 'apply/filter',
  loadingModal = 'loading/modal',
  setError = 'set/error',
  setSearch = 'set/search',
}

export const addUserMR = {
  CASA: 'casa',
  NON_CASA: 'non-casa',
  MOBILE: 'mobile',
  CIF: 'cif',
};

export const notificationStatus = {
  ENABLE: 'ENABLE',
  DISABLE: 'DISABLE',
};
export const timeDropdown = [
  { value: '00:00 am' },
  { value: '00:30 am' },
  { value: '01:00 am' },
  { value: '01:30 am' },
  { value: '02:00 am' },
  { value: '02:30 am' },
  { value: '03:00 am' },
  { value: '03:30 am' },
  { value: '04:00 am' },
  { value: '04:30 am' },
  { value: '05:00 am' },
  { value: '05:30 am' },
  { value: '06:00 am' },
  { value: '06:30 am' },
  { value: '07:00 am' },
  { value: '07:30 am' },
  { value: '08:00 am' },
  { value: '08:30 am' },
  { value: '09:00 am' },
  { value: '09:30 am' },
  { value: '10:00 am' },
  { value: '10:30 am' },
  { value: '11:00 am' },
  { value: '11:30 am' },
  { value: '12:00 pm' },
  { value: '12:30 pm' },
  { value: '01:00 pm' },
  { value: '01:30 pm' },
  { value: '02:00 pm' },
  { value: '02:30 pm' },
  { value: '03:00 pm' },
  { value: '03:30 pm' },
  { value: '04:00 pm' },
  { value: '04:30 pm' },
  { value: '05:00 pm' },
  { value: '05:30 pm' },
  { value: '06:00 pm' },
  { value: '06:30 pm' },
  { value: '07:00 pm' },
  { value: '07:30 pm' },
  { value: '08:00 pm' },
  { value: '08:30 pm' },
  { value: '09:00 pm' },
  { value: '09:30 pm' },
  { value: '10:00 pm' },
  { value: '10:30 pm' },
  { value: '11:00 pm' },
  { value: '11:30 pm' },
  { value: '11:59 pm' },
];

export const timeDayFilterValues = [0, 1, 2, 3, 4, 5, 6];

export enum VpaStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
  BLOCKED = 'BLOCKED',
  TEMP_BLOCKED = 'TEMP_BLOCKED',
  BLOCK = 'BLOCK',
  UNBLOCK = 'UNBLOCK',
}

export enum AmountOperation {
  BLOCK = 'BLOCK',
  RELEASE = 'RELEASE',
}

export const persona1 = 'persona1';
export interface docimg {
  state: boolean;
  img: string;
  docType?: string;
}

export const handleSettlementType: Items[] = [
  { text: t('realTime'), value: 'REAL_TIME' },
  { text: t('onDemand'), value: 'ON_DEMAND' },
];
export const InputStatusTypes = {
  NO_ROLE_FOUND: 'NO_ROLE_FOUND',
  ROLE_FOUND: 'ROLE_FOUND',
  TYPING: 'TYPING',
  NONE: '',
};

export const InputCifStatusConst = {
  NO_CIF_R_FOUND: 'NO_CIF_R_FOUND',
  CIF_R_FOUND: 'CIF_R_FOUND',
  ERROR: 'ERROR',
  NONE: '',
  LOADING: 'LOADING',
};

export const activeAccountStatus = ['6', '8'];
export const MerchantActiveInactiveStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const ChqueFileExtensions = [
  'image/jpeg',
  'image/png',
  // 'application/pdf',
];
export const AUBL = 'AUBL';

export const roleMappedUsersLimit = {
  min: 0,
  max: 50,
};

export const DeviceType = [
  { text: 'Select', value: '' },
  { text: 'NEXGO', value: 'NEXGO' },
  { text: 'OMA', value: 'OMA' },
  { text: 'MANTRA', value: 'MANTRA' },
];

export const DefaultID = '1';
export const NoData = '-/-';

export const LIABILITY = 'LIABILITY';
export const IFSC = 'AUBL0000001';
export const TYPE_ADMIN = 'admin';
