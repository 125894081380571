import { useState } from 'react';
import {
  createStaticRanges,
  DateRange,
  DateRangePicker,
} from 'react-date-range';

import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { t } from 'i18next';

import { DEFAULT_MODULE } from '@modules/account-statement/constants/account-statement.constant';
import { Collection_Module } from '@modules/qr-collection/constants/qr-collection.constant';

import './AU_DateRangePicker.style.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

interface DateRangeProps {
  id: string;
  months?: number;
  moduleType?: string;
  callBackFunc?: (item) => void;
  start?: string;
  end?: string;
}

export const definedTimes = {
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfDay(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfLastThreeMonths: startOfMonth(addMonths(new Date(), -2)),
  startOfLastThreeMonthsAccount: startOfMonth(addMonths(new Date(), -3)),
  startOfLastSixMonths: startOfMonth(addMonths(new Date(), -6)),
  startOfLastYear: startOfMonth(addMonths(new Date(), -12)),
};

const getRangeForThreeMonths = (currModule) => {
  return {
    startDate:
      currModule === DEFAULT_MODULE || currModule === Collection_Module
        ? definedTimes.startOfLastThreeMonthsAccount
        : definedTimes.startOfLastThreeMonths,
    endDate:
      currModule === DEFAULT_MODULE || currModule === Collection_Module
        ? definedTimes.endOfLastMonth
        : definedTimes.startOfToday,
  };
};

const func = (currentModule) => {
  return createStaticRanges([
    {
      label: t('toDay'),
      range: () => ({
        startDate: definedTimes.startOfToday,
        endDate: definedTimes.endOfToday,
      }),
    },
    {
      label: t('yesterDay'),
      range: () => ({
        startDate: definedTimes.startOfYesterday,
        endDate: definedTimes.endOfYesterday,
      }),
    },
    {
      label: t('thisWeek'),
      range: () => ({
        startDate: definedTimes.startOfWeek,
        endDate: definedTimes.endOfWeek,
      }),
    },
    {
      label: t('lastWeek'),
      range: () => ({
        startDate: definedTimes.startOfLastWeek,
        endDate: definedTimes.endOfLastWeek,
      }),
    },
    {
      label: t('thisMonth'),
      range: () => ({
        startDate: definedTimes.startOfMonth,
        endDate: definedTimes.endOfToday,
      }),
    },
    {
      label: t('lastMonth'),
      range: () => ({
        startDate: definedTimes.startOfLastMonth,
        endDate: definedTimes.endOfLastMonth,
      }),
    },
    {
      label: t('lastThreeMonth'),
      range: () => getRangeForThreeMonths(currentModule),
    },
    {
      label: t('lastSixMonths'),
      range: () => ({
        startDate: definedTimes.startOfLastSixMonths,
        endDate: definedTimes.endOfLastMonth,
      }),
    },
    {
      label: t('lastOneYear'),
      range: () => ({
        startDate: definedTimes.startOfLastYear,
        endDate: definedTimes.endOfLastMonth,
      }),
    },
  ]);
};

const addThreeMonthFunc = (currentModule) => {
  if (!currentModule) return func(currentModule);

  func(currentModule).forEach((item) => {
    if (item.label === t('lastThreeMonth')) {
      item.range = () => getRangeForThreeMonths(currentModule);
    }
  });
};

export const DateRangeChecker = (initDate, endDate, currentModule) => {
  addThreeMonthFunc(currentModule);
  return func(currentModule).find((item) => {
    return (
      isSameDay(item.range().startDate, initDate) &&
      isSameDay(item.range().endDate, endDate)
    );
  });
};

export const AU_DateRangePicker: React.FC<DateRangeProps> = ({
  id,
  months = 2,
  moduleType,
  callBackFunc,
  start,
  end,
  ...otherprops
}: DateRangeProps) => {
  const [state, setState] = useState([
    {
      startDate: start === '' ? new Date() : start,
      endDate: end === '' ? new Date() : end,
      key: 'selection',
    },
  ]);

  const [mobileViewState, setMobileViewState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection',
    },
  ]);

  const callChangeFunc = (item) => {
    setState([
      {
        startDate: item.selection.startDate,
        endDate: item.selection.endDate,
        key: 'selection',
      },
    ]);
    setMobileViewState([item.selection]);
    callBackFunc?.(item.selection);
  };

  addThreeMonthFunc(moduleType);
  return (
    <div className="date-range">
      <div id={id} className="web-view-date-range">
        <DateRangePicker
          onChange={(item) => {
            callChangeFunc(item);
          }}
          moveRangeOnFirstSelection={false}
          months={months}
          staticRanges={func(moduleType)}
          showSelectionPreview={false}
          ranges={state}
          rangeColors={['var(--primary-600)']}
          autoComplete="off"
          color="var(--primary-600)"
          maxDate={new Date()}
          direction="horizontal"
          {...otherprops}
        />
      </div>
      <div className="mobile-view-date">
        <DateRange
          editableDateInputs={true}
          onChange={(item) => callChangeFunc(item)}
          moveRangeOnFirstSelection={false}
          autoComplete="off"
          ranges={mobileViewState}
          rangeColors={['var(--primary-600)']}
          color="var(--primary-600)"
          maxDate={new Date()}
          showSelectionPreview={false}
          {...otherprops}
        />
      </div>
    </div>
  );
};
