import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { breadcrumb } from '@core-constants';

import { Icon } from '../icon/XB_Icon.component';

import './AU_Breadcrumb.style.scss';

interface BreadcrumbItemInterface {
  url: string;
  name: string;
  id: number;
  parents: number[];
}
export function BreadcrumbItem(obj: BreadcrumbItemInterface) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <React.Fragment>
      {obj.id === 1 || obj.id === 5 ? (
        <Icon
          icon="home"
          height={16}
          width={14}
          color="none"
          className="ml-1 mr-3"
        />
      ) : (
        ''
      )}
      {obj.id === 1 ? (
        ''
      ) : (
        <Icon
          icon="pay-icon"
          height={16}
          width={14}
          color="none"
          className="ml-1 mr-3"
        />
      )}
      <li
        itemProp="itemListElement"
        itemScope
        className={`breadcrumb-element mr-2 current-ele cursor-pointer ${
          obj.url === useLocation().pathname
            ? 'text-orange-600'
            : 'text-gray-400'
        }`}
      >
        <a
          itemProp="item"
          className="breadcrumb-element__link"
          onClick={() => {
            obj.url && navigate(obj.url, { state: location.state });
          }}
        >
          <span itemProp="name" className="breadcrumb">
            {obj.name}
          </span>
        </a>
      </li>
    </React.Fragment>
  );
}
export const AU_Breadcrumb = ({
  navigations,
  mainClass,
  itemClass,
}: {
  navigations?: BreadcrumbItemInterface[];
  mainClass?: string;
  itemClass?: string;
}) => {
  const currentPageUrl = useLocation().pathname;
  const [urls, setUrls] = useState<BreadcrumbItemInterface[]>([]);
  navigations = navigations ?? breadcrumb;
  useEffect(() => {
    const currentBreadObj = navigations?.find(
      (item) => item.url === currentPageUrl
    );
    if (currentBreadObj) {
      const parents = navigations?.filter((item) =>
        currentBreadObj?.parents.includes(item.id)
      );

      setUrls([...(parents ?? []), currentBreadObj]);
    } else {
      setUrls([]);
    }
  }, [location.href]);
  return (
    <nav
      className={'c-breadcrumb bg-white m-text-sm-regular w-full '.concat(
        mainClass ?? 'px-3 pt-4 pl-4'
      )}
    >
      <ol
        itemScope
        className={'breadcrumb__list flex '.concat(itemClass ?? '')}
      >
        {urls.map((item, index) => (
          <BreadcrumbItem {...item} key={index.toString()} />
        ))}
      </ol>
    </nav>
  );
};
