import { AUEndPoints, Service } from '@core-services';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { MRLimitDsbGetReq } from '../../models/limit-management-mr.model';

export const merchantLimitManagentThunk = createAsyncThunk(
  'limit-mangement/Global',
  async (obj: MRLimitDsbGetReq, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: AUEndPoints.limitService.DASHBOARD_GET,
        url: '',
        obj,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);
