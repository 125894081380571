import { useEffect } from 'react';

import { v4 } from 'uuid';

import { useEventProvider } from '@core-providers';
import { isEmpty, SessionStorage } from '@core-utils';

interface HOCProps {
  children: any;
  name: string;
  extraParams:
    | {
        journeyId: string;
        screenId: string;
      }
    | undefined;
}

export const XB_HOC = ({ children, extraParams, name }: HOCProps) => {
  const { setJourneyId, setScreenId, logScreenView, logCustomEvent } =
    useEventProvider();

  const generateDeviceId = () => {
    const uuid = v4();

    const STORAGE_NAME = 'browser_id';
    const existingID = SessionStorage.getItem(STORAGE_NAME);
    if (isEmpty(existingID)) {
      SessionStorage.setItem(STORAGE_NAME, uuid);
    }
  };

  useEffect(() => {
    generateDeviceId();
  }, []);

  useEffect(() => {
    document.getElementById('root_id')?.scrollTo(0, 0);
    if (extraParams) {
      const screen = `${extraParams.journeyId}_${extraParams.screenId}`;
      setJourneyId(extraParams.journeyId);
      setScreenId(extraParams.screenId);
      logScreenView(screen, name);
      logCustomEvent(screen);
    }
  }, [extraParams]);
  return <div>{children}</div>;
};
