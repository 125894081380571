import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { XB_Button, XB_HOC, XB_Modal } from '@core-components/atoms';
import { XB_DefaultLayout } from '@core-components/templates';
import { EventProvider, useEventProvider } from '@core-providers';
import {
  AppDispatch,
  initializeApp,
  logout,
  RootState,
  store,
} from '@core-stores';
import { DASHBOARD_TYPE } from '@src/core-constants';
import { useAlertBox } from '@src/core-hooks';
import {
  setBrowserNavigation,
  setNavThemeType as setNavThemeTypeRdx,
} from '@src/core-stores/slices/init.slice';
import { Storage } from '@src/core-utils';
import PageNotFound from '@src/modules/mr-login/screens/page-not-found.screen';
import { setNavThemeType } from '@src/themes/utils.theme';

export interface RedirectRouteType {
  origin: string;
  destination: string;
}
export interface RoutePropsType {
  path: string;
  name: string;
  header?: string;
  children?: RoutePropsType[];
  component?: React.FC<any> | null;
  extraParams?: { journeyId: string; screenId: string };
}

export const BrowserNavigationHOC = ({
  children,
  refreshNavigation,
}: {
  children: React.ReactNode;
  refreshNavigation: string;
}) => {
  const isBrowserBack = useRef(true);
  const navigate = useNavigate();
  const auAlert = useAlertBox();
  const { subscribeEvent } = useEventProvider();
  const location = useLocation();
  const { t } = useTranslation();
  const [confirmBack, setConfirmBack] = useState(false);
  const el = useRef(document.createElement('div'));
  const currentElement = el.current;
  const [dynamic] = useState(!currentElement.parentElement);
  const backURL = '/auth/login';
  const {
    browserNavigation,
  }: {
    browserNavigation: {
      global: boolean;
      page: boolean;
    };
  } = useSelector<RootState>((state: RootState) => state.init) as any;
  const dispatch = useDispatch<AppDispatch>();

  const isLogin = useSelector<RootState>(
    (state: RootState) => state.auth.loggedIn
  );

  const logoutApiHeader = {
    sessionId: sessionStorage.getItem('sessionId') ?? '',
    refreshToken: sessionStorage.getItem('refreshToken') ?? '',
  };

  const confirmContinue = async () => {
    if (isLogin) {
      const response = dispatch(logout(logoutApiHeader));
      response
        .unwrap()
        .then(async (_resp) => {
          store.dispatch({ type: 'USER_LOGOUT' });
          setTimeout(() => {
            dispatch(setNavThemeTypeRdx(DASHBOARD_TYPE.STORE));
            auAlert.success(t('logoutSuccessfully').toString());
          }, 50);
          dispatch(initializeApp());
          setNavThemeType(DASHBOARD_TYPE.STORE);
          Storage.setItemAsync('LANGUAGE', 'en');
          Storage.setItemAsync('LANGUAGE-NAME', 'English');
          navigate(backURL, { replace: true });
        })
        .catch((err) => {
          auAlert.error(err?.displayMessage);
        });
    } else {
      navigate(backURL, { replace: true });
    }
    setConfirmBack(false);
  };

  useEffect(() => {
    const unsubscribe = subscribeEvent('PREV_PAGE', () => {
      isBrowserBack.current = false;
      navigate(-3);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (dynamic) {
      document.body.appendChild(currentElement);
    }
    return () => {
      if (dynamic && currentElement.parentElement) {
        currentElement.parentElement.removeChild(currentElement);
      }
    };
  }, [currentElement, dynamic]);

  useEffect(() => {
    const onUnload = (e) => {
      sessionStorage.removeItem('logout');
      sessionStorage.setItem('page-nav', 'reload');
      // Recommended method to show default browser popup
      e.preventDefault();
      // Included for legacy support, e.g. Chrome/Edge < 119
      e.returnValue = true;
      return true;
    };
    const handlePopstate = (event) => {
      if (!isBrowserBack.current) {
        isBrowserBack.current = true;
      } else {
        event.preventDefault();
        if (!confirmBack) {
          setConfirmBack((prev) => !prev);
        }
      }
    };
    if (!browserNavigation.global && !browserNavigation.page) {
      window.addEventListener('beforeunload', onUnload);
      window.history.pushState(window.history.state, '', window.location.href);
      window.addEventListener('popstate', handlePopstate);
    }
    return () => {
      window.removeEventListener('beforeunload', onUnload);
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [browserNavigation]);

  useEffect(() => {
    if (!browserNavigation.global && !browserNavigation.page) {
      window.history.pushState(window.history.state, '', window.location.href);
    }
  }, [browserNavigation, location]);
  useEffect(() => {
    if (sessionStorage.getItem('page-nav') === 'reload') {
      sessionStorage.removeItem('page-nav');
      dispatch(logout(logoutApiHeader));
      navigate(refreshNavigation);
    }
  }, [refreshNavigation]);
  return (
    <>
      {children}
      {confirmBack && (
        <>
          {createPortal(
            <>
              <XB_Modal
                setOpen={setConfirmBack}
                open={confirmBack}
                title={t('areYouSureYouWantToLeave')}
                subTitle={t('changesYouMadeMayNotSave')}
                actions={
                  <div className="flex gap-3">
                    <XB_Button
                      btnType="secondary-gray"
                      dataTestId="MDL-BACK-CNCL"
                      classes="w-full"
                      onClick={() => {
                        window.history.pushState(
                          window.history.state,
                          '',
                          window.location.href
                        );
                        setConfirmBack(false);
                      }}
                    >
                      {t('cancel')}
                    </XB_Button>

                    <XB_Button
                      dataTestId="MDL-BACK-CONT"
                      classes="w-full"
                      onClick={() => {
                        confirmContinue();
                      }}
                    >
                      {t('continue')}
                    </XB_Button>
                  </div>
                }
              ></XB_Modal>
            </>,
            el.current
          )}
        </>
      )}
    </>
  );
};

const renderRoutes = (key: number, route: RoutePropsType, isRoot = true) => {
  let Element: React.ReactNode;
  if (route.component) {
    const RouteFunctionalComponent = route.component;
    if (isRoot) {
      Element = (
        <BrowserNavigationHOC refreshNavigation={'/auth/login'}>
          <XB_HOC extraParams={route?.extraParams} name={route.name}>
            <RouteFunctionalComponent />
          </XB_HOC>
        </BrowserNavigationHOC>
      );
    } else {
      Element = (
        <XB_HOC extraParams={route?.extraParams} name={route.name}>
          <RouteFunctionalComponent />
        </XB_HOC>
      );
    }
  } else {
    Element = <XB_DefaultLayout />;
  }
  return (
    <Route key={key} path={route.path} element={Element}>
      {route.children?.map((childRoute: RoutePropsType, childIndex: number) =>
        renderRoutes(childIndex, childRoute, false)
      )}
    </Route>
  );
};

const childRoutes = (routes: RoutePropsType[]) => {
  return routes.map((route: RoutePropsType, index: number) =>
    renderRoutes(index, route)
  );
};

function AppRoutes({
  routes,
  redirectRoutes,
  browserNavigation = false,
}: {
  routes: RoutePropsType[];
  redirectRoutes: RedirectRouteType[];
  browserNavigation: boolean;
}) {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(setBrowserNavigation({ global: browserNavigation }));
  }, [browserNavigation]);
  return (
    <>
      <SwitchToTop />
      <EventProvider>
        <Routes>
          {childRoutes(routes)}
          {redirectRoutes.map((route, index) => (
            <Route
              key={`redirect-route-${index}`}
              path={route.origin}
              element={<Navigate to={route.destination} />}
            />
          ))}
          <Route key={`pagenot found`} path="*" element={<PageNotFound />} />
        </Routes>
      </EventProvider>
    </>
  );
}

const SwitchToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
export const Navigation = ({
  routes,
  redirectRoutes,
  browserNavigation,
}: {
  routes: RoutePropsType[];
  redirectRoutes: RedirectRouteType[];
  browserNavigation: boolean;
}) => {
  return (
    <div>
      <Router>
        <AppRoutes
          routes={routes}
          redirectRoutes={redirectRoutes}
          browserNavigation={browserNavigation}
        />
      </Router>
    </div>
  );
};
