import { useTranslation } from 'react-i18next';

import { XB_Button, XB_Modal } from '@core-components/atoms';
import { appConstants } from '@core-constants';
import { getBlobUrl } from '@core-services';
import { isPdfDoc } from '@modules/admin-portal/utils';
import PdfDocImage from '@src/assets/icons/image_thumbnail.svg';

import './XB_PdfModal.style.scss';

interface PdfModalProps {
  url?: string;
  title?: string;
  handleModal?: () => void;
  showButtons?: boolean;
  setOpen: (boolean) => void;
  open: boolean;
  closeModal?: (args: boolean) => void;
  urlImage: string;
}
interface EmbedTagProps {
  url: string;
  width: string;
  height: string;
}
export const EmbedTag: React.FC<EmbedTagProps> = ({
  url,
  width,
  height,
}: EmbedTagProps) => {
  return (
    <embed
      src={`data:application/pdf;base64,${url}#toolbar=0`}
      type="application/pdf"
      width={width}
      height={height}
    />
  );
};

export const XB_PdfModal: React.FC<PdfModalProps> = ({
  url,
  title,
  handleModal,
  showButtons = true,
  setOpen,
  open,
  closeModal,
  urlImage,
}: PdfModalProps) => {
  const { t } = useTranslation();

  const handleDownload = (image, bucketName) => {
    void (async () => {
      const blobUrl = await getBlobUrl(image, bucketName);
      window.open(blobUrl, '_blank', 'popup');
    })();
  };
  return (
    <>
      <div className="pdf-modal" onClick={handleModal}>
        <div className="parellel-div"></div>
        {isPdfDoc(urlImage) ? (
          <img src={PdfDocImage} width="70px" height="70px" alt="" />
        ) : (
          <EmbedTag url={url ?? ''} width="70px" height="70px" />
        )}
      </div>
      <XB_Modal
        title={title}
        setOpen={setOpen}
        open={open}
        body={
          <>
            <div id="downloadContent">
              <EmbedTag url={url ?? ''} width="450px" height="450px" />
            </div>
            {showButtons && (
              <div className="flex justify-between w-full mt-6">
                <XB_Button
                  dataTestId="CNCL"
                  btnType="secondary-gray"
                  onClick={closeModal}
                  classes="w-full mr-2"
                >
                  {t(`close`)}
                </XB_Button>
                <XB_Button
                  dataTestId="DWLD"
                  onClick={() =>
                    handleDownload(urlImage, appConstants.AWSBUCKETS.MSMS)
                  }
                  classes="w-full ml-2"
                >
                  {t(`download`)}
                </XB_Button>
              </div>
            )}
          </>
        }
      ></XB_Modal>
    </>
  );
};
