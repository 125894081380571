import React, { useEffect, useState } from 'react';

import './AU_Progressbar.style.scss';

interface ProgressBarProps {
  progress: number; // progress should be a number between 0 to 100 for linear & for semi-circle 50 to 100
  type?: 'linear' | 'semi-circle';
  startIndex?: string;
  endIndex?: string;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  type = 'linear',
  startIndex,
  endIndex,
}) => {
  const [windowWidth, setWindowWidth] = useState(0);
  const resizeWindow = () => {
    setWindowWidth(
      window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
    );
  };
  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  const findLinearBarColor = () => {
    if (progress >= 40) return 'Healthy';
    else if (progress < 40 && progress >= 20) return 'Moderate';
    else return 'Critical';
  };

  const findSemiCircleBarColor = () => {
    if (progress >= 90) return 'Healthy';
    else if (progress < 90 && progress >= 70) return 'Moderate';
    else return 'Critical';
  };
  const renderProgressbar = (): React.ReactNode => {
    let element: React.JSX.Element;
    if (type === 'linear') {
      const safeProgress = Math.max(0, Math.min(100, progress));
      element = (
        <div className="progress-bar-container">
          <div
            className={`progress-bar-fill__${findLinearBarColor()}`}
            style={{ width: `${safeProgress}%` }}
          ></div>
        </div>
      );
    } else {
      const normalizedProgress = Math.max(0, Math.min(progress, 100));

      // Calculate the stroke-dasharray based on the progress
      const radius = 100; // Radius of the semi-circle
      const circumference = 2 * Math.PI * radius;
      const strokeValue = ((100 - normalizedProgress) / 100) * circumference;

      element = (
        <div className="semi-circle-container">
          {startIndex && (
            <div className="start-index">
              <p className="m-text-md-regular">{startIndex}</p>
            </div>
          )}
          <svg
            className="semi-circle-svg"
            width={windowWidth < 640 ? '300' : '400'}
            height={windowWidth < 640 ? '100' : '150'}
            viewBox={windowWidth < 640 ? '0 0 150 90' : '0 0 200 100'}
          >
            <defs>
              <clipPath id="semi-circle-clip">
                <rect x="-20" y="0" width="400" height="110" rx="15" />
              </clipPath>
            </defs>
            <g clipPath="url(#semi-circle-clip)">
              <circle
                className="semi-circle-background"
                cx="100"
                cy="110"
                r={radius}
                strokeWidth={20}
              />
              <circle
                className={`semi-circle-progress__${findSemiCircleBarColor()}`}
                cx="100"
                cy="110"
                r={radius}
                strokeWidth="8"
                strokeDasharray={circumference}
                strokeDashoffset={strokeValue}
              />
            </g>
          </svg>
          {endIndex && (
            <div className="end-index">
              <p className="m-text-md-regular">{endIndex}</p>
            </div>
          )}
        </div>
      );
    }
    return element;
  };

  return <div>{renderProgressbar()}</div>;
};
