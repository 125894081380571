const { REACT_APP_API_BASE_URL, REACT_APP_STRAPI_URL } = process.env;

export const EndPoints = {
  onboardingService: {
    getCoordinates: (address: string) => {
      return {
        pincodeCoordinates: `${REACT_APP_API_BASE_URL}/api/onboarding-service/google-map?address=${address}`,
      };
    },
    downloadFile: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/download`,
    pollAPI: `${REACT_APP_API_BASE_URL}/api/onboarding-service/application/status`,
    getDocuments: `${REACT_APP_API_BASE_URL}/api/case-management-service/case-management/get-uploaded-documents`,
    preserveEntityAddress: `${REACT_APP_API_BASE_URL}/api/onboarding-service/document/preserve-poe-address-details`,
    settlementData: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/preserve-product-detail`,
    displayNameUrl: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/preserve-qr-display-name`,
    ckycStatus: `${REACT_APP_API_BASE_URL}/api/onboarding-service/ckyc/cbs-kyc-status`,
    ckycDetails: `${REACT_APP_API_BASE_URL}/api/onboarding-service/ckyc/cbs-ckyc-detail`,
    verifyPAN: (confirmationValue: boolean) => {
      return {
        verifyPan: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/verify-pan?userConfirmation=${confirmationValue.toString()}`,
      };
    },
    saveConstitution: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/preserve-constitution-type`,
    setAdditionPersonalDtl: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/preserve-additional-personal-details`,
    setBusinessDtl: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/preserve-business-details`,
    setAdditionalBusinessDtl: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/preserve-additional-business-details`,
    suggestVpa: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/suggest-vpa`,
    createVpa: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/create-vpa`,
    listVpa: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/fetch-vpa-list`,
    deleteVpa: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/delete-vpa`,
    uploadFile: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/upload-document`,
    deleteFile: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/remove`,
    fileMetadata: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/get-uploaded-documents-metadata`,
    saveVpaAddress: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/preserve-address-preference`,
    savePreferredAddress: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/preserve-address-preference`,
    saveEditVpaAddressUrl: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/preserve-address-details`,
    preserveSignAndShopImage: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/preserve-sign-and-shop-image`,
    getSummary: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/fetch-summary`,
    cifCreation: `${REACT_APP_API_BASE_URL}/api/onboarding-service/application/submission`,
    sendEmailNotification: `${REACT_APP_API_BASE_URL}/api/onboarding-service/notification/send-email-notification`,
    cifDetails: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/preserve-cif-details`,
    getLiabilityAccount: `${REACT_APP_API_BASE_URL}/api/onboarding-service/settlement-account/fetch-account-details`,
    saveLiabilityAccount: `${REACT_APP_API_BASE_URL}/api/onboarding-service/settlement-account/preserve-liability-account-details`,
    preservePoeDetails: `${REACT_APP_API_BASE_URL}/api/onboarding-service/document/preserve-poe-details`,
    fetchSavedPoeDetails: `${REACT_APP_API_BASE_URL}/api/onboarding-service/document/fetch-poe-details`,
    nameMatchPoe: `${REACT_APP_API_BASE_URL}/api/onboarding-service/matches/name`,
    searchGstDocs: `${REACT_APP_API_BASE_URL}/api/onboarding-service/document/search-gst`,
    fetchGstDetails: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/fetch-gst-details`,
    getAadhaarByToken: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/fetch-aadhaar`,
    getAadhaarToken: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/fetch-token`,
    validateJourneyStatus: `${REACT_APP_API_BASE_URL}/api/onboarding-service/resume/journey/validate`,
    validateResumeJourney: `${REACT_APP_API_BASE_URL}/api/onboarding-service/resume/journey/validate-and-get-details`,
    validateResumeV1: `${REACT_APP_API_BASE_URL}/api/onboarding-service/resume/journey/v1/validate-and-get-details`,
    deleteOnboardingUser: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/delete-onboarding-user`,
    cbsCall: `${REACT_APP_API_BASE_URL}/api/onboarding-service/account/update-cbs-risk-profile`,
    initVideoKyc: `${REACT_APP_API_BASE_URL}/api/onboarding-service/video-box/init/vkyc`,
    videoKycStatus: `${REACT_APP_API_BASE_URL}/api/onboarding-service/video-box/vkyc/update-vt-status`,
    videoKycPoll: `${REACT_APP_API_BASE_URL}/api/onboarding-service/video-box/poll/vkyc`,
    fetchSupportingDocuments: `${REACT_APP_API_BASE_URL}/api/onboarding-service/supporting-doc/fetch`,
    createOrUpdateSupportingDocuments: `${REACT_APP_API_BASE_URL}/api/onboarding-service/supporting-doc/create-and-update`,
    removeSupportingDocuments: `${REACT_APP_API_BASE_URL}/api/onboarding-service/supporting-doc/remove`,
    verifyPoeDetails: `${REACT_APP_API_BASE_URL}/api/onboarding-service/document/verify-poe-details`,
    verifyAccount: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/imps35/verify`,
    saveLocation: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/preserve-address-coordinates`,
    saveDraftData: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/preserve-drafted-details`,
    preserveDob: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/preserve-dob`,
    isAuEmployee: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/is-au-employee`,
  },
  keycloakService: {
    soAuth: `${REACT_APP_API_BASE_URL}/api/keycloak-service/authenticate?redirect=so`,
    adminAuth: `${REACT_APP_API_BASE_URL}/api/keycloak-service/authenticate?redirect=admin`,
  },
  masterService: {
    cityNames: `${REACT_APP_API_BASE_URL}/api/master-service/masters/fetch/city`,
    salutationsTypes: `${REACT_APP_API_BASE_URL}/api/master-service/masters/salutations`,
    educationTypes: `${REACT_APP_API_BASE_URL}/api/master-service/masters/educations`,
    maritalTypes: `${REACT_APP_API_BASE_URL}/api/master-service/masters/fetch/marital-status`,
    occupationTypes: `${REACT_APP_API_BASE_URL}/api/master-service/masters/occupations`,
    constitutionTypes: `${REACT_APP_API_BASE_URL}/api/master-service/masters/constitution-types`,
    premisesTypes: `${REACT_APP_API_BASE_URL}/api/master-service/masters/type-of-premises`,
    businessFilter: `${REACT_APP_API_BASE_URL}/api/master-service/masters/income-profile-filter`,
    annualTurnoverDetails: `${REACT_APP_API_BASE_URL}/api/master-service/masters/annual-turnover-details`,
    annualIncomeDetails: `${REACT_APP_API_BASE_URL}/api/master-service/masters/annual-income-details`,
    annualTurnoverDetailsV1: `${REACT_APP_API_BASE_URL}/api/master-service/masters/v1/annual-turnover-details`,
    ifscCodes: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/get-bank-detail-by-ifsc`,
    negativeWords: `${REACT_APP_API_BASE_URL}/api/master-service/masters/fetch/negative-keyword`,
    documentTypes: `${REACT_APP_API_BASE_URL}/api/master-service/masters/communication-address-proofs`,
    disposalDomain: `${REACT_APP_API_BASE_URL}/api/master-service/masters/disposal-email-domain`,
    getPincodeDetails: `${REACT_APP_API_BASE_URL}/api/master-service/masters/get-pincode-detail`,
    settlementCycle: `${REACT_APP_API_BASE_URL}/api/master-service/masters/fetch/settlement-cycle`,
    fetchPoeDocuments: `${REACT_APP_API_BASE_URL}/api/master-service/masters/fetch/poe-document`,
    fetchPoeAreaCode: `${REACT_APP_API_BASE_URL}/api/master-service/masters/fetch/area-code`,
    fetchPoeMedicalCouncil: `${REACT_APP_API_BASE_URL}/api/master-service/masters/fetch/medical-council`,
    checkJourney: `${REACT_APP_API_BASE_URL}/api/master-service/masters/fetch/political-exposure-journey`,
    consentVersionList: `${REACT_APP_API_BASE_URL}/api/user-management-service/consent-versions/list`,
    savedUserConsents: `${REACT_APP_API_BASE_URL}/api/user-management-service/user-consents/list`,
    captureConsentsList: `${REACT_APP_API_BASE_URL}/api/user-management-service/user-consents/capture`,
    fetchDocumentTypes: `${REACT_APP_API_BASE_URL}/api/master-service/masters/document-types`,
  },
  notificationService: {
    sendOTP: `${REACT_APP_API_BASE_URL}/api/user-management-service/otp/send`,
    sendOTPOnEmail: `${REACT_APP_API_BASE_URL}/api/user-management-service/otp/send-on-email`,
    validateOTP: `${REACT_APP_API_BASE_URL}/api/user-management-service/otp/validate`,
  },
  inventoryService: {
    vpaStatus: `${REACT_APP_API_BASE_URL}/api/inventory-service/sold/items-available`,
  },
  userMgmtService: {
    fetchUserDetails: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/merchant/fetch`,
    dedupeLite: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/dedupe-lite`,
    dedupeUserV1: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/v1/dedupe`,
    dedupeLiteV1: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/v1/dedupe-lite`,
    saveUserDetails: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/merchant/capture-user-info`,
    usernameAvailability: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/username/availability`,
    setupUserIdentity: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/setup/identity`,
    resetUserIdentity: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/reset/identity`,
    userRegistration: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/merchant/registration`,
    userRegistrationV1: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/merchant/v1/registration`,
    deleteRegistration: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/merchant/delete`,
    doAadhaarService: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/verify-aadhaar`,
    fetchAddressDetails: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/fetch-address-details`,
    saveAddress: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/preserve-address-details`,
    deleteAddress: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/remove-address-details`,
    saveEditAddress: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/update-address-details`,
    fetchUserProfile: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/profile`,
    fetchEmployeeDetail: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/merchant/fetch`,

    fetchUserGroup: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/group/name`,
    searchUser: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/search-user`,
    fetchEmployee: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/employee/fetch`,
    userAmlCheck: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/user-aml-check`,
    fetchUserGroupList: (page = 0, size = 10) => {
      return `${REACT_APP_API_BASE_URL}/api/user-management-service/user/group/list?page=${page}&size=${size}`;
    },
    fetchUserGroupListUnfilter: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/group/list`,
    mobileQueryParam: (mobileNumber: string) => {
      return {
        livePhoto: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/verify-image?mobileNumber=${mobileNumber}`,
      };
    },
    email: (email: string) => {
      return {
        karzaValidate: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/email-authentication?email=${email}`,
      };
    },
    mobileNumberStatus: `${REACT_APP_API_BASE_URL}/api/user-management-service/registration/mobile-number-status`,
  },
  authenticationService: {
    authentication: `${REACT_APP_API_BASE_URL}/api/authentication-service/web/login`,
    authRefreshToken: `${REACT_APP_API_BASE_URL}/api/authentication-service/token/refresh`,
  },
  so: {
    accountPreference: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/preserve-account-preference`,
  },
  strapiService: {
    filterLang: (lang: string, version?: string) => {
      return {
        STRAPI_LANGUAGE_JSON: `${REACT_APP_STRAPI_URL}/api/getLabels?code=${lang}&version=${version}`,
        STRAPI_CAROUSEL_DTL: `${REACT_APP_STRAPI_URL}/api/walkthroughs?populate=*&filters%5Blanguage%5D%5B$eq%5D=${lang}`,
        getConsentList: `${REACT_APP_STRAPI_URL}/api/getConsents?code=${lang}`,
        getConsentByVersion: `${REACT_APP_STRAPI_URL}/api/getConsentByVersionId?code=${lang}&versionId=${version}`,
      };
    },
    getWalkthrough: `${REACT_APP_STRAPI_URL}/api/getWalkthrough`,
    getDebitCardImages: `${REACT_APP_STRAPI_URL}/api/getDebitCardImages`,
    getQRJourneySteps: (userType: string, customerType: string) =>
      `${REACT_APP_STRAPI_URL}/api/getOnboardingTabs?userType=${userType}&customerType=${customerType}`,
    getPersona: (persona: string) =>
      `${REACT_APP_STRAPI_URL}/personas?filters[name][$eq]=${persona}&populate=widgets,widgets.contents,widgets.contents.items`,
  },
  admin: {
    roasterDetails: `${REACT_APP_API_BASE_URL}/api/case-management-service/case-management/get-roaster-details`,
    assignCases: `${REACT_APP_API_BASE_URL}/api/case-management-service/case-action/assign-cases`,
    caseManagement: `${REACT_APP_API_BASE_URL}/api/case-management-service/case-management/cdp`,
    preserveFieldActions: `${REACT_APP_API_BASE_URL}/api/case-management-service/case-action/preserve-field-action`,
    resetField: `${REACT_APP_API_BASE_URL}/api/case-management-service/case-action/reset`,
    getFieldAction: `${REACT_APP_API_BASE_URL}/api/case-management-service/case-action/get-field-action-details`,
    getCheckpointReasons: `${REACT_APP_API_BASE_URL}/api/master-service/masters/checkpoint-reasons?checkPoint=`,
    getReferReasons: `${REACT_APP_API_BASE_URL}/api/master-service/masters/refer-reason?checkpointId=`,
    getReviewDetails: `${REACT_APP_API_BASE_URL}/api/case-management-service/case-management/get-review-details`,
    sidemenu: `${REACT_APP_API_BASE_URL}/api/master-service/masters/side-menu`,
    listing: `${REACT_APP_API_BASE_URL}/api/case-management-service/case-management/listing`,
    caseHistory: `${REACT_APP_API_BASE_URL}/api/case-management-service/case-management/get-case-history`,
    caseStatus: `${REACT_APP_API_BASE_URL}/api/case-management-service/case-management/get-case-status`,
    fileUpload: `${REACT_APP_API_BASE_URL}/api/case-management-service/document/upload`,
    userGroup: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/group/detail/list`,
    nextAssignment: `${REACT_APP_API_BASE_URL}/api/case-management-service/case-management/get-assignee-details`,
    thread: `${REACT_APP_API_BASE_URL}/api/case-management-service/case-management/threads`,
    actionable: `${REACT_APP_API_BASE_URL}/api/case-management-service/case-action/checkpoint-actionable-viewable`,
    withdrawCase: `${REACT_APP_API_BASE_URL}/api/case-management-service/case-action/withdraw-case`,
    getCurrentCPCUser: `${REACT_APP_API_BASE_URL}/api/case-management-service/case-action/get-current-cpc-user`,
    getEmployeeManager: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/employee/fetch`,
    reassignNewUser: `${REACT_APP_API_BASE_URL}/api/case-management-service/case-action/reassign-cpc-user`,
  },
  caseManagement: {
    downloadFile: `${REACT_APP_API_BASE_URL}/api/case-management-service/document/download`,
  },
  configService: {
    get: `${REACT_APP_API_BASE_URL}/api/config-service/config/get`,
    getReferConfig: `${REACT_APP_API_BASE_URL}/api/config-service/config/get`,
    configs: (userType: string) => {
      return {
        languageList: `${REACT_APP_API_BASE_URL}/api/config-service/global-configs/list/key?userType=${userType}`,
        allConfig: `${REACT_APP_API_BASE_URL}/api/config-service/global-configs/latest?userType=${userType}`,
      };
    },
  },
};
