import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { Icon, XB_Divider } from '@core-components/atoms';
import {
  appConstants,
  ENTITLEMENT,
  HOME_NAV_TITLE_DATA,
  PERMISSIONS,
  userGroup,
  userGroupList,
} from '@core-constants';
import { SessionStorage } from '@core-utils';
import {
  MODULE_NAME,
  MODULE_NAME_APP_CONFIGURATION,
  MODULE_NAME_LIMIT,
  MODULE_NAME_SETTLEMENT,
} from '@modules/admin-portal/constants';
import { useAppSelector } from '@src/core-stores';

import './AU_AdminSideNavbar.style.scss';

const { REACT_APP_TYPE } = process.env;

export const AU_AdminSideNavbar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const userData = SessionStorage.getItem('userProfile');
  const parsedData = JSON.parse(userData!);
  const [isOpen, setIsOpen] = useState({});
  const {
    RBAC_ADMIN_USER_GROUP,
    RBAC_ADMIN_AUTH_GROUP,
    RBAC_ADMIN_USERS,
    RBAC_ROLES,
    RBAC_ADMIN_MERCHANT_DETAILS,
  } = PERMISSIONS;

  interface NavProps {
    labelKey: string;
    path: string;
    children: InavChild[];
  }

  interface InavChild {
    labelKey: string;
    path: string;
    isDisabled: boolean;
    permission?: string[];
    connectedPath?: string[];
  }

  let dataArr: NavProps[] = [];
  let titleObj = HOME_NAV_TITLE_DATA.navMenuAdminInventory;

  const userPermissionsList = useAppSelector(
    (state) => state.profile.data?.permissions
  );

  if (REACT_APP_TYPE === appConstants.APP_TYPE.ADMIN) {
    dataArr = userGroupList.navMenuAdminInventory;
    if (location.pathname.includes(MODULE_NAME)) {
      titleObj = HOME_NAV_TITLE_DATA.navMenuAdminEntitlement;
      dataArr = userGroupList.navMenuAdminEntitlement;
    }
    if (location.pathname.includes(MODULE_NAME_LIMIT)) {
      titleObj = HOME_NAV_TITLE_DATA.navMenuAdminLimitManagement;
      dataArr = userGroupList.navMenuAdminLimitManagement;
    }
    if (location.pathname.includes(MODULE_NAME_SETTLEMENT)) {
      titleObj = HOME_NAV_TITLE_DATA.navMenuAdminSettlementManagement;
      dataArr = userGroupList.navMenuSettlement;
    }
    if (location.pathname.includes(MODULE_NAME_APP_CONFIGURATION)) {
      titleObj = HOME_NAV_TITLE_DATA.navMenuAdminAppConfiguration;
      dataArr = userGroupList.navMenuAppConfiguration;
    }
  } else if (REACT_APP_TYPE === appConstants.APP_TYPE.SO) {
    if (parsedData) {
      const userType = parsedData.find((item) =>
        [userGroup.SO_USER, userGroup.BM_USER].includes(item?.name)
      );
      if (userType && userGroup.SO_USER === userType?.name) {
        dataArr = userGroupList.navMenuSo;
      } else if (userType && userGroup.BM_USER === userType?.name) {
        dataArr = userGroupList.navMenuBm;
      }
    }
  }

  const onHandleMenu = (value) => {
    setIsOpen((prev) => {
      const obj = { ...prev };
      if (obj[value]) {
        obj[value] = false;
      } else {
        obj[value] = true;
      }
      return obj;
    });
  };

  const onClickClose = () => {
    const ele: any = document.getElementById('admin_navbar_container');
    if (ele) {
      ele.classList.toggle('--open');
    }
  };

  const handleClassNames = (path) => {
    if (path?.includes(location?.pathname)) {
      return 'm-text-lg-bold text-orange-600';
    }
    return 'm-text-lg-regular text-gray-900';
  };

  const ButtonPermissionCheck = (btName) => {
    let btPermissions: string[] = [];
    if (btName === ENTITLEMENT.EMPLOYEE) {
      btPermissions = [
        RBAC_ADMIN_USER_GROUP.VIEW,
        RBAC_ADMIN_AUTH_GROUP.VIEW,
        RBAC_ADMIN_USERS.VIEW,
        RBAC_ROLES.VIEW,
      ];
      return btPermissions.some((el) => userPermissionsList?.includes(el));
    } else if (btName === ENTITLEMENT.MERCHANT) {
      btPermissions = [
        RBAC_ROLES.VIEW,
        RBAC_ADMIN_AUTH_GROUP.VIEW,
        RBAC_ADMIN_MERCHANT_DETAILS.VIEW,
      ];
      return btPermissions.some((el) => userPermissionsList?.includes(el));
    }
    return true;
  };

  const navLinkFn = (obj: InavChild) => {
    const userHaveLinkAccess =
      obj?.permission?.filter((permission) =>
        userPermissionsList?.includes(permission)
      ) ?? [];

    if (obj?.permission) {
      if (userHaveLinkAccess.length > 0) {
        return (
          <NavLink
            to={obj.path}
            key={obj.labelKey}
            className={`${
              obj?.isDisabled
                ? 'pointer-events-none m-text-lg-regular text-gray-400'
                : handleClassNames(obj?.connectedPath)
            }`}
          >
            {t(obj.labelKey)}
          </NavLink>
        );
      } else {
        return <></>;
      }
    }
    return (
      <NavLink
        to={obj.path}
        key={obj.labelKey}
        className={`${
          obj?.isDisabled
            ? 'pointer-events-none m-text-lg-regular text-gray-400'
            : handleClassNames(obj?.path)
        }`}
      >
        {t(obj.labelKey)}
      </NavLink>
    );
  };

  useEffect(() => {
    userGroupList.navMenuAdminEntitlement.forEach((urlObj) => {
      urlObj.children.forEach((el) => {
        el?.connectedPath?.includes(location.pathname);
        onHandleMenu(urlObj?.labelKey);
      });
    });
  }, []);

  return (
    <section id="admin_navbar_container" className="navbar_container">
      <div className="w-[13.5rem]">
        <div className="flex px-6 ">
          <div className="flex">
            <NavLink to={titleObj?.url}>
              <div className="flex">
                <Icon icon="back" color="text-gray-900" />
                <h1 className="text-gray-900  m-text-lg-medium ml-[10px]">
                  {t(titleObj?.nameKey)}
                </h1>
              </div>
            </NavLink>
            <span className="admin-close" onClick={onClickClose}>
              <Icon icon="close" color="text-gray-900" />
            </span>
          </div>
        </div>
        <XB_Divider classes="mt-[22px]" />
        {dataArr?.map((item, index) => {
          return ButtonPermissionCheck(item?.labelKey) ? (
            <div key={index} className="px-6 mt-4">
              {item?.labelKey && (
                <span
                  className="m-text-lg-bold text-neutrals-900 flex justify-between"
                  onClick={() => onHandleMenu(item?.labelKey)}
                >
                  {t(item?.labelKey)}
                  {item?.children?.length > 0 && (
                    <Icon
                      icon="chevron-up-arrow"
                      className={
                        isOpen[item.labelKey]
                          ? 'rotate-arrow-up'
                          : 'rotate-arrow-down'
                      }
                      color="none"
                    />
                  )}
                </span>
              )}

              {(item?.labelKey === '' ? true : isOpen[item?.labelKey]) && (
                <div className="nav-list">
                  {item.children.map((obj) => {
                    return navLinkFn(obj);
                  })}
                </div>
              )}
            </div>
          ) : (
            <></>
          );
        })}
      </div>
    </section>
  );
};
