import React, {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useEventProvider } from '@core-providers';
import { isEmpty } from '@core-utils';
import { autoUpdate, computePosition, flip } from '@floating-ui/react';

import { XB_Button } from '../button/XB_Button.component';
import { XB_Chip } from '../chip/XB_Chip.component';
import { XB_Divider } from '../divider/XB_Divider.component';
import { Icon } from '../icon/XB_Icon.component';
import { XB_Input } from '../input/XB_Input.component';

import './XB_Dropdown.styles.scss';

interface ISecItem {
  icon: React.ReactElement;
  secondaryText: string;
}
interface DropdownProps {
  id: string;
  label?: string;
  classes?: string;
  msgType?: 'info' | 'success' | 'error' | '';
  infoMsgText?: string;
  disabled?: boolean;
  items: Array<{
    text?: string;
    value: string;
    icon?: ReactNode;
    secondaryText?: string | ISecItem[];
  }>;
  placeholderOption?: string;
  selectedValue?: string[] | string;
  multiSelect?: boolean;
  onChange?: (val) => void;
  actionBtnText?: string | ReactNode;
  search?: boolean;
  actionBtnHandler?: () => void;
  noSearchResultText?: string;
  joinValue?: string;
  showDropDownMenus?: 'OPEN' | 'CLOSE';
  dropDownclicked?: () => void;
}

export const XB_Dropdown: React.FC<DropdownProps> = ({
  id,
  label,
  classes,
  onChange,
  msgType,
  infoMsgText,
  disabled,
  items,
  selectedValue = '',
  placeholderOption,
  multiSelect = false,
  actionBtnText,
  search = false,
  actionBtnHandler,
  noSearchResultText,
  joinValue = ',',
  showDropDownMenus,
  dropDownclicked,
}: DropdownProps) => {
  const SelectActions = {
    Close: 0,
    CloseSelect: 1,
    First: 2,
    Last: 3,
    Next: 4,
    Open: 5,
    PageDown: 6,
    PageUp: 7,
    Previous: 8,
    Select: 9,
    Type: 10,
  };
  const { getEventId } = useEventProvider();
  const classList = ['dropdown'];
  classes && classList.push(classes);
  disabled && classList.push('disabled');
  multiSelect && classList.push('multiSelect');
  const [value, setValue] = useState(
    Array.isArray(selectedValue) ? [...selectedValue] : [selectedValue]
  );
  const [showMenu, setShowMenu] = useState(false);
  const comboxRef = useRef<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const [itemsList, setItemsList] = useState(items);
  const [currentIdx, setCurrentIdx] = useState(-1);
  const { t } = useTranslation();
  const [error, setError] = useState('');

  const handleMenuOpenKeyPress = useCallback((menuOpen, key, altKey) => {
    if (menuOpen) {
      if (key === 'ArrowUp' && altKey) {
        return SelectActions.CloseSelect;
      } else if (key === 'ArrowDown' && !altKey) {
        return SelectActions.Next;
      } else if (key === 'ArrowUp') {
        return SelectActions.Previous;
      } else if (key === 'PageUp') {
        return SelectActions.PageUp;
      } else if (key === 'PageDown') {
        return SelectActions.PageDown;
      } else if (key === 'Escape' || key === 'Tab') {
        return SelectActions.Close;
      } else if (key === 'Enter' || key === ' ') {
        return SelectActions.CloseSelect;
      }
    }
  }, []);

  const getActionFromKey = useCallback((event, menuOpen) => {
    const { key, altKey, ctrlKey, metaKey } = event;
    const openKeys = ['ArrowDown', 'ArrowUp', 'Enter', ' ']; // all keys that will do the default open action
    // handle opening when closed
    if (!menuOpen && openKeys.includes(key)) {
      return SelectActions.Open;
    }

    // home and end move the selected option when open or closed
    if (key === 'Home') {
      return SelectActions.First;
    }
    if (key === 'End') {
      return SelectActions.Last;
    }

    // handle typing characters when open or closed
    if (
      key === 'Backspace' ||
      key === 'Clear' ||
      (key === ' ' && search) ||
      (key?.length === 1 && key !== ' ' && !altKey && !ctrlKey && !metaKey)
    ) {
      return SelectActions.Type;
    }

    // handle keys when open
    return handleMenuOpenKeyPress(menuOpen, key, altKey);
  }, []);

  const getUpdatedIndex = useCallback(
    (currentIndex: number, maxIndex, action) => {
      const pageSize = 3; // used for pageup/pagedown
      switch (action) {
        case SelectActions.First:
          return 0;
        case SelectActions.Last:
          return maxIndex;
        case SelectActions.Previous:
          return Math.max(0, currentIndex - 1);
        case SelectActions.Next:
          return Math.min(maxIndex, currentIndex + 1);
        case SelectActions.PageUp:
          return Math.max(0, currentIndex - pageSize);
        case SelectActions.PageDown:
          return Math.min(maxIndex, currentIndex + pageSize);
        default:
          return currentIndex;
      }
    },
    []
  );

  const selectDropdownOption = useCallback(
    (optionValue) => {
      let selectionValue: string[] = [];
      if (multiSelect) {
        const indexOfValue = value.indexOf(optionValue);
        selectionValue =
          indexOfValue > -1
            ? [
                ...value.filter((item) => {
                  return item !== optionValue;
                }),
              ]
            : [...value, optionValue];

        setTimeout(() => {
          setShowMenu(true);
        }, 0);
      } else {
        selectionValue = [optionValue];
      }
      setValue(selectionValue);
      if (!multiSelect) setShowMenu(false);
      onChange?.(selectionValue.join(joinValue));
    },
    [value, multiSelect, setShowMenu, setValue, onChange]
  );

  const updateDropdownState = useCallback(
    (open: boolean) => {
      if (showMenu === open) {
        return;
      }
      setShowMenu(open);
      comboxRef?.current?.setAttribute('aria-expanded', open.toString());
    },
    [showMenu, comboxRef, setShowMenu]
  );

  const onComboKeyDown = useCallback(
    (event) => {
      const max = itemsList?.length - 1;
      const action = getActionFromKey(event, showMenu);

      switch (action) {
        case SelectActions.Last:
        case SelectActions.First:
          updateDropdownState(true);
        // intentional fallthrough
        case SelectActions.Next:
        case SelectActions.Previous:
        case SelectActions.PageUp:
        case SelectActions.PageDown:
          event.preventDefault();
          setCurrentIdx(getUpdatedIndex(currentIdx, max, action));
          break;
        case SelectActions.CloseSelect:
          event.preventDefault();
          return selectDropdownOption(itemsList[currentIdx]?.value);
        case SelectActions.Close:
          event.preventDefault();
          return updateDropdownState(false);
        case SelectActions.Open:
          event.preventDefault();
          return updateDropdownState(true);
      }
    },
    [
      itemsList,
      showMenu,
      currentIdx,
      updateDropdownState,
      getUpdatedIndex,
      setCurrentIdx,
      selectDropdownOption,
    ]
  );

  useEffect(() => {
    comboxRef?.current?.addEventListener('keydown', onComboKeyDown);
    return () => {
      comboxRef?.current?.removeEventListener('keydown', onComboKeyDown);
    };
  }, [onComboKeyDown]);

  useEffect(() => {
    const dropdown = comboxRef.current as HTMLDivElement;
    const dropdownList = dropdown.querySelector(
      '.dropdown__list'
    ) as HTMLDivElement;
    autoUpdate(dropdown, dropdownList, () => {
      computePosition(dropdown, dropdownList, {
        placement: 'bottom',
        middleware: [flip()],
      }).then(({ x, y }) => {
        Object.assign(dropdownList.style, {
          left: `${x}px`,
          top: `${y}px`,
        });
      });
    });
  }, [showMenu]);

  useEffect(() => {
    if (showDropDownMenus === 'CLOSE') setShowMenu(false);
  }, [showDropDownMenus]);

  useLayoutEffect(() => {
    setItemsList(items);
  }, [items]);

  useLayoutEffect(() => {
    const handleOutSideClick = (e) => {
      if (!comboxRef?.current?.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener('click', handleOutSideClick);

    return () => {
      window.removeEventListener('click', handleOutSideClick);
    };
  }, []);

  useLayoutEffect(() => {
    setValue(
      Array.isArray(selectedValue) ? [...selectedValue] : [selectedValue]
    );
    setCurrentIdx(items?.findIndex((obj) => obj.value === selectedValue));
  }, [selectedValue]);

  useLayoutEffect(() => {
    if (showMenu && search) {
      document
        .getElementById(`${id}-search-parent`)
        ?.classList?.remove('hidden');
      document.getElementById(`${id}-search`)?.focus();
    }
    if (!showMenu && search) {
      document.getElementById(`${id}-search-parent`)?.classList?.add('hidden');
      (document.getElementById(`${id}-search`) as HTMLInputElement).value = '';
      onSearch('');
    }
    if (!showMenu) {
      setCurrentIdx(items?.findIndex((obj) => obj.value === selectedValue));
    }
  }, [showMenu]);

  const onOptionClicked = (event: React.MouseEvent<HTMLDivElement>, idx) => {
    const selectedOption = event.currentTarget;
    const optionValue = selectedOption.dataset.value ?? '';
    setCurrentIdx(idx);
    selectDropdownOption(optionValue);
  };

  const toggleDropdown = () => {
    setShowMenu((prev) => !prev);
  };

  const selectedItems = itemsList?.filter((item) => value.includes(item.value));

  const onSearch = (val) => {
    if (val.length >= 2) {
      const searchVal = val.toLowerCase();
      const searchedList = items.filter((i) => {
        const itemText = i.text?.toLowerCase() ?? '';
        const itemValue = i.value?.toLowerCase() ?? '';
        const itemSecondaryValue = i?.secondaryText ?? '';
        if (itemText || itemValue || itemSecondaryValue) {
          return (
            itemValue.includes(searchVal) ||
            itemText.includes(searchVal) ||
            itemSecondaryValue.includes(searchVal)
          );
        }
        return false;
      });

      if (searchedList.length === 0) {
        setError(t(noSearchResultText ?? 'noItemsFound'));
      } else {
        setError('');
        setItemsList(searchedList);
      }
    } else {
      setItemsList(items);
    }
    if (val.length === 0) {
      setError('');
    }
  };

  const getDropdownValue = () => {
    const emptyDropdownValue = () => {
      return (
        <span className="placeholder">
          {itemsList[0]?.icon && (
            <span className="option__icon">{itemsList[0]?.icon}</span>
          )}
          <span className="placeholder-text">{placeholderOption}</span>
        </span>
      );
    };
    const filledDropdownValue = () => {
      if (multiSelect) {
        return (
          <div className="dropdown-tags">
            {selectedItems.map((option) => (
              <XB_Chip
                key={option.value}
                classes="dropdown-tag-item"
                rightIcon="close"
                onClick={() => {
                  selectDropdownOption(option.value);
                }}
              >
                {option?.text}
              </XB_Chip>
            ))}
          </div>
        );
      }
      return (
        <>
          {selectedItems?.[0]?.icon && (
            <span className="option__icon">{selectedItems[0]?.icon}</span>
          )}
          <span className="option__text">
            <span className="text">{selectedItems?.[0]?.text}</span>
          </span>
        </>
      );
    };
    return (
      <div className="dropdown__value">
        {placeholderOption && selectedItems?.length === 0
          ? emptyDropdownValue()
          : filledDropdownValue()}
      </div>
    );
  };
  return (
    <>
      <div
        className={`${classList.join(' ')}`}
        aria-label={label}
        id={`dropdown_parent-${id}`}
        ref={comboxRef}
      >
        {label && (
          <label htmlFor={id} className="m-text-md-regular mb-1.5 block">
            {label}
          </label>
        )}
        <div
          className={`dropdown__wrapper ${showMenu ? 'expanded' : 'collapsed'}`}
          onClick={() => {
            dropDownclicked?.();
          }}
        >
          {search && (
            <div
              id={`${id}-search-parent`}
              className="absolute z-10 hidden w-full"
            >
              <XB_Input
                id={`${id}-search`}
                value={searchValue}
                placeholder={placeholderOption}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  return onSearch(e.target.value);
                }}
                prefixIcon="search"
                suffixIcon={searchValue !== '' ? 'close' : ''}
                autoComplete="false"
                onIconClick={() => {
                  setSearchValue('');
                  return onSearch('');
                }}
              />
            </div>
          )}
          <div
            aria-controls={`${id}-list`}
            aria-expanded="false"
            aria-haspopup="listbox"
            aria-labelledby="combo1-label"
            id={id}
            data-testid={`${getEventId(id)}-DDN`}
            className={`dropdown__input ${
              search ? 'dropdown__searchable' : ''
            }`}
            role="comboBox"
            tabIndex={0}
            onClick={() => {
              !disabled && toggleDropdown();
            }}
          >
            <>
              {getDropdownValue()}
              <span
                onClick={(e) => {
                  e.preventDefault();
                  setSearchValue('');
                }}
                className="dropdown__chevron"
              >
                <Icon icon="chevron-down" width={20} height={20} />
              </span>
            </>
          </div>
          <div
            className="dropdown__list"
            role="listbox"
            id={`${id}-list`}
            aria-labelledby="combo1-label"
            tabIndex={-1}
          >
            {actionBtnText && (
              <>
                <XB_Button
                  btnType="tertiary"
                  size="sm"
                  className="dropdown__action"
                  onClick={actionBtnHandler}
                  dataTestId={'CRET-BTN'}
                >
                  {actionBtnText}
                </XB_Button>
                <XB_Divider />
              </>
            )}
            {search && error && error !== '' ? (
              <div className="pb-2 mt-3 text-center text-gray-300 m-text-lg-regular">
                {error}
              </div>
            ) : (
              itemsList?.map((item, idx) => {
                const isSelectedItem = value.includes(item.value);
                return (
                  <div
                    key={`${id}-option-${idx}`}
                    role="option"
                    tabIndex={0}
                    id={`${id}-option-${idx}`}
                    className="text-base dropdown__option"
                    aria-selected={idx === currentIdx}
                    data-value={item.value}
                    onClick={(event) => onOptionClicked(event, idx)}
                  >
                    {multiSelect && (
                      <span className="checkbox">
                        {isSelectedItem && (
                          <Icon icon="check" width={14} height={14} />
                        )}
                      </span>
                    )}
                    <div className="option__value">
                      {item?.icon && (
                        <span className="option__icon">{item?.icon}</span>
                      )}
                      <span className="option__text">
                        <span className="text">{item?.text}</span>
                        <span className="secondary-text">
                          {Array.isArray(item?.secondaryText) &&
                          !isEmpty(item?.secondaryText) ? (
                            <>
                              {item?.secondaryText?.map((secItem, index) => {
                                return (
                                  <Fragment key={index}>
                                    <div className="flex items-center gap-x-1 mt-1">
                                      {secItem?.icon}
                                      {secItem?.secondaryText}
                                    </div>
                                  </Fragment>
                                );
                              })}
                            </>
                          ) : (
                            <div>{item?.secondaryText as string}</div>
                          )}
                        </span>
                      </span>
                    </div>
                    {!multiSelect && isSelectedItem && (
                      <span className="check-mark">
                        <Icon icon="check" width={18} height={18} />
                      </span>
                    )}
                  </div>
                );
              })
            )}
          </div>
        </div>

        {msgType && (
          <span className="error" id={`${id}-error`}>
            {infoMsgText}
          </span>
        )}
      </div>
    </>
  );
};
