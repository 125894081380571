import { useTranslation } from 'react-i18next';

import { getColor, isEmpty } from 'core-utils';

import {
  AU_RoundBox,
  ButtonProps,
  Icon,
  XB_Button,
} from '@core-components/atoms';

import './AU_HeaderOffer.style.scss';

interface HeaderOfferProps {
  title: string | undefined;
  subtitle: string | undefined;
  offerBackgroundColor?: string;
  offerHeadingColor?: string;
  offerSubheadingColor?: string;
  onCancel: () => void;
  imgUrl: string;
  primaryBtnProps: ButtonProps;
}

export const AU_HeaderOffer = ({
  title = '',
  subtitle = '',
  offerHeadingColor = '#ffffff',
  offerSubheadingColor = '#ffffff',
  offerBackgroundColor = '',
  onCancel,
  imgUrl,
  primaryBtnProps,
}: HeaderOfferProps) => {
  const { t } = useTranslation();
  return (
    <div className="bank_offer_container">
      <div
        className="offer_container_card"
        style={{
          backgroundColor: isEmpty(imgUrl)
            ? getColor(offerBackgroundColor)
            : '',
        }}
        onClick={primaryBtnProps.onClick}
      >
        {imgUrl && <img className="banner-img absolute" src={imgUrl} alt="" />}
        <div
          className={`flex flex-col h-full z-50 relative pl-2 justify-center ${
            primaryBtnProps.children ? 'w-2/3' : 'w-4/5'
          }`}
        >
          <span
            style={{ color: getColor(offerHeadingColor) }}
            className={`text-left  title-container m-display-md-bold text-black`}
          >
            {t(title)}
          </span>
          <span
            style={{ color: getColor(offerSubheadingColor) }}
            className={`text-left subtitle-container m-text-md-medium text-black `}
          >
            {t(subtitle)}
          </span>
        </div>

        {primaryBtnProps && (
          <XB_Button
            iconName={primaryBtnProps.children ? '' : `pay-icon`}
            backgroundColor={getColor(primaryBtnProps.backgroundColor ?? '')}
            classes="offer_container_card_button"
            {...primaryBtnProps}
          >
            {primaryBtnProps.children
              ? t(primaryBtnProps.children as string)
              : ''}
          </XB_Button>
        )}

        <div
          onClick={(e) => {
            e.stopPropagation();
            onCancel();
          }}
        >
          <AU_RoundBox
            width="20px"
            height="20px"
            boxClass="offer_container_card_close_badge"
          >
            <Icon
              icon="close"
              width={13}
              height={13}
              className="text-neutrals-700"
            />
          </AU_RoundBox>
        </div>
      </div>
    </div>
  );
};
