import { incomeTurnoverEndpoints } from '@core-constants';
import { EndPoints, Service } from '@core-services';

export const getConstitutionTypes = async (refreshCache: boolean) => {
  const reqObj = {
    baseurl: EndPoints.masterService.constitutionTypes,
    method: 'POST',
    url: '',
    obj: {
      refresh_cache: refreshCache,
    },
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const saveConstitutionTypes = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.saveConstitution,
    method: 'POST',
    url: '',
    obj,
  };
  const response = await Service.send(reqObj);
  return response.data;
};
export const fetchPoeDocuments = async () => {
  const reqObj = {
    baseurl: EndPoints.masterService.fetchPoeDocuments,
    method: 'POST',
    url: '',
    obj: {
      refreshCache: true,
    },
  };
  const resp = await Service.send(reqObj);
  return resp.data;
};
export const fetchPoeMedicalCouncil = async () => {
  const reqObj = {
    baseurl: EndPoints.masterService.fetchPoeMedicalCouncil,
    method: 'POST',
    url: '',
    obj: {
      refreshCache: true,
    },
  };
  const resp = await Service.send(reqObj);
  return resp.data;
};
export const fetchPoeAreaCode = async () => {
  const reqObj = {
    baseurl: EndPoints.masterService.fetchPoeAreaCode,
    method: 'POST',
    url: '',
    obj: {
      refreshCache: true,
    },
  };
  const resp = await Service.send(reqObj);
  return resp.data;
};
export const fetchAnnualMoneyDetails = async (isInd) => {
  const baseUrl = isInd ? 'annualIncomeDetails' : 'annualTurnoverDetails';
  const reqObj = {
    baseurl: EndPoints.masterService[baseUrl],
    method: 'POST',
    url: '',
    obj: {
      refreshCache: true,
    },
  };
  const resp = await Service.send(reqObj);
  return resp.data;
};

export const fetchV1AnnualTurnover = async (isInd) => {
  const baseUrl = isInd
    ? incomeTurnoverEndpoints.income
    : incomeTurnoverEndpoints.turnover;
  const reqObj = {
    baseurl: EndPoints.masterService[baseUrl],
    method: 'POST',
    url: '',
    obj: {
      refreshCache: true,
    },
  };
  const resp = await Service.send(reqObj);
  return resp.data;
};

export const fetchDocumentTypes = async () => {
  const reqObj = {
    baseurl: EndPoints.masterService.fetchDocumentTypes,
    method: 'POST',
    url: '',
    obj: {
      refreshCache: true,
    },
  };
  const resp = await Service.send(reqObj);
  return resp.data;
};
