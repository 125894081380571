import { AUEndPoints, EndPoints, Service } from '@core-services';
import { flushUserSession, SessionStorage } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  LoginData,
  LogoutHeader,
  ProfileHeader,
} from '../models/auth-res.model';

export const login = createAsyncThunk(
  'user/login',
  async (loginData: LoginData, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: EndPoints.authenticationService.authentication,
        url: '',
        obj: loginData,
      });

      return {
        username: loginData.username,
        token: response.data.data,
        loggedIn: response.data.status === 'success',
      };
    } catch (error: any) {
      const errordata = {
        displayMessage: error.displayMessage,
        bucketMessage: error.bucketMessage,
        serverMessage: error.serverMessage,
      };
      return rejectWithValue(errordata);
    }
  }
);
export const logout = createAsyncThunk(
  'user/logout',
  async (header: LogoutHeader, { rejectWithValue }) => {
    try {
      const response = await Service.send(
        {
          method: 'POST',
          baseurl: AUEndPoints.merchantService.LOGOUT,
          url: '',
          obj: { request: '' },
        },

        header
      );
      return {
        loggedOut: response.data.status === 'success',
      };
    } catch (error: any) {
      const errordata = {
        displayMessage: error.displayMessage,
        bucketMessage: error.bucketMessage,
        serverMessage: error.serverMessage,
      };
      return rejectWithValue(errordata);
    } finally {
      flushUserSession();
    }
  }
);

export const authRefreshToken = createAsyncThunk(
  'user/refreshtoken',
  async (_, { rejectWithValue }) => {
    const refreshToken = SessionStorage.getItem('refreshToken');
    const header = {
      refreshToken,
    };
    try {
      const response = await Service.send(
        {
          method: 'POST',
          baseurl: EndPoints.authenticationService.authRefreshToken,
          url: '',
        },
        header
      );

      return response.data;
    } catch (error: any) {
      const errordata = {
        displayMessage: error.displayMessage,
        bucketMessage: error.bucketMessage,
        serverMessage: error.serverMessage,
      };
      return rejectWithValue(errordata);
    }
  }
);

export const getUserProfile = createAsyncThunk(
  'user/profile',
  async (ProfileData: ProfileHeader, { rejectWithValue, fulfillWithValue }) => {
    const generateBaseUrl = () => {
      const merchantIdParam = ProfileData.merchantId
        ? `?merchantId=${ProfileData.merchantId}`
        : '';
      return `${EndPoints.userMgmtService.fetchUserProfile}${merchantIdParam}`;
    };

    try {
      const response = await Service.send({
        baseurl: generateBaseUrl(),
        url: '',
        method: 'POST',
      });
      return fulfillWithValue(response.data);
    } catch (error: any) {
      const errordata = {
        displayMessage: error.displayMessage,
        bucketMessage: error.bucketMessage,
        serverMessage: error.serverMessage,
      };
      return rejectWithValue(errordata);
    }
  }
);
