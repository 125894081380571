import { AUEndPoints, Service } from '@core-services';
import { IFetchLoanDetailsReq } from '@core-stores';
import { handleError, logError } from '@core-utils';

/** AU Team
 * @description fetch cross sell url for redirect to third party
 * @param reqData
 * @returns
 */
export const fetchCrossSellLoanDetails = async (
  reqData: IFetchLoanDetailsReq
) => {
  const reqObj = {
    baseurl: AUEndPoints.loansService.LOAN_CROSS_SELL_FETCH,
    method: 'POST',
    url: '',
    obj: reqData,
  };
  const response = await Service.send(reqObj);
  if (
    response.data === null ||
    response.data?.status === 'error' ||
    response.data?.data === null
  ) {
    return handleError(response.data);
  }

  return response.data;
};

/** AU Team
 * @description fetch cross sell url for redirect to third party
 * @param reqData
 * @returns
 */
export const fetchCrossSellEquityTrading = async (
  reqData: IFetchLoanDetailsReq
) => {
  const reqObj = {
    baseurl: AUEndPoints.loansService.EQUITY_CROSS_SELL_FETCH,
    method: 'POST',
    url: '',
    obj: reqData,
  };
  const response = await Service.send(reqObj);
  if (
    response.data === null ||
    response.data?.status === 'error' ||
    response.data?.data === null
  ) {
    return handleError(response.data);
  }

  return response.data;
};

export const fetchVideoBankingRedirection = async (
  reqData: IFetchLoanDetailsReq
) => {
  const reqObj = {
    baseurl: AUEndPoints.serviceRequest.VIDEO_BANKING_REDIRECT,
    method: 'POST',
    url: '',
    obj: reqData,
  };
  const response = await Service.send(reqObj);
  if (
    response.data === null ||
    response.data?.status === 'error' ||
    response.data?.data === null
  ) {
    return handleError(response.data);
  }
  return response.data;
};

export const fetchOffersRedirection = async (reqData: IFetchLoanDetailsReq) => {
  const reqObj = {
    baseurl: AUEndPoints.serviceRequest.OFFERS_REDIRECT,
    method: 'POST',
    url: '',
    obj: reqData,
  };
  const response = await Service.send(reqObj);
  if (
    response.data === null ||
    response.data?.status === 'error' ||
    response.data?.data === null
  ) {
    return handleError(response.data);
  }
  return response.data;
};

export const fetchSCFURL = async () => {
  const reqObj = {
    baseurl: AUEndPoints.serviceRequest.SUPPLY_CHAIN_FINANCE,
    method: 'POST',
    url: '',
    obj: '',
  };
  const response = await Service.send(reqObj);
  if (
    response.data === null ||
    response.data?.status === 'error' ||
    response.data?.data === null
  ) {
    return handleError(response.data);
  }
  return response.data;
};

/** AU team
 * @description create hidden form and submit for cross sell redirection
 * @param url
 * @param reqData
 */
export const redirectToUrl = async (
  url,
  reqData: { data: string; merchant: string }
) => {
  const mapForm = document.createElement('form');
  mapForm.target = '_blank';
  mapForm.method = 'POST';
  mapForm.action = url;

  const mapInput = document.createElement('input');
  mapInput.type = 'hidden';
  mapInput.name = 'data';
  mapInput.value = reqData.data;
  mapForm.appendChild(mapInput);

  const mapInput2 = document.createElement('input');
  mapInput2.type = 'hidden';
  mapInput2.name = 'merchant';
  mapInput2.value = reqData.merchant;
  mapForm.appendChild(mapInput2);

  document.body.appendChild(mapForm);

  mapForm.submit();
};

/** AU team
 * @description create hidden form and submit for cross sell redirection
 * @param url
 * @param reqData
 */
export const redirectToUrlGet = async (
  url,
  reqData: { data: string; merchant: string }
) => {
  const headers = {
    data: reqData.data,
    merchant: reqData.merchant,
  };
  fetch(url, { headers })
    .then((response) => response.blob())
    .then((blob) => {
      const _url = window.URL.createObjectURL(blob);
      window.open(_url, '_blank', 'noreferrer')?.focus();
    })
    .catch((err) => {
      logError(err);
    });
};
