import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  AU_Loader,
  AU_RoundBox,
  Icon,
  XB_Button,
  XB_Modal,
} from '@core-components/atoms';
import {
  ACCOUNT_TYPES,
  appConstants,
  CHANNEL_REF_NO,
  CROSS_SELL_NAME,
  DASHBOARD_TYPE,
  ONBOARDING_CONFIG_KEY,
  POSE_VINE_RETURN_URL,
  QR_ONBOARDING_NAV,
} from '@core-constants';
import { useAlertBox } from '@core-hooks';
import {
  fetchOffersRedirection,
  fetchSCFURL,
  fetchVideoBankingRedirection,
  redirectToUrl,
} from '@core-services';
import {
  AuthState,
  crmLeadGen,
  ICRMLeadConfig,
  IFetchLoanDetailsReq,
  IFetchLoanDetailsResp,
  RootState,
  useAppDispatch,
  useAppSelector,
} from '@core-stores';
import { customerAccountFilter, getFEConfig, isEmpty } from '@core-utils';
import { navLinkPathArrays } from '@modules/au-dashboard/constants/au-dashboard.constant';
import {
  CRMLEAD_CONFIG,
  IPopUpData,
  LEAD_SOURCE,
  ntbStorePopupData,
  TYPE_OF_CUSTOMER,
} from '@modules/qr-collection/constants/qr-collection.constant';
import { IResumeJourney } from '@modules/qr-collection/models/qr-collection.model';
import {
  CrmLeadSuccessModal,
  QrOnboardingNotAllowedModal,
} from '@modules/qr-collection/screens/partial-collection-ui.screen';
import { checkIsAuEmployee } from '@modules/qr-collection/services/qr-collection.service';
import { setCifStaffStatusData } from '@modules/qr-collection/stores/slices/qr-onboarding.slice';
import { cifStaffStatusFlags, FLAG } from '@modules/qr-onboarding/constant';
import { IOnboardingConfigData } from '@modules/qr-onboarding/models/onboarding-config.model';
import { Form15ErrorModal } from '@modules/service-request/screens/form-15gh/form-15-gh-error-model.screen';
import { CIF } from '@src/mocks/deposit-opening.mock';
import { getNavThemeFromLocal, setNavThemeType } from '@src/themes/utils.theme';

import { setNavThemeType as setNavThemeTypeRdx } from '../../../core-stores/slices/init.slice';
import ApplyForQr from '@src/assets/images/apply-for-qr-banner.png';

import './AU_Sidenavlink.style.scss';

interface SidenavlinkProps {
  label?: string;
  optionMenu?: Array<{
    rbac?: string;
    optionMenuIcon: string;
    title: string;
    pathname: string;
  }>;
  isExpandedSidebar: boolean;
  prefixIconName?: string;
  suffixIconName?: string;
  labelPathname?: string;
  isDropdown?: boolean;
  rbac?: string;
  navThemeType?: string;
  resetDropdown?: () => boolean;
  openSidebar?: (val: boolean) => void;
}

export const AU_Sidenavlink = ({
  label,
  optionMenu,
  prefixIconName,
  suffixIconName,
  rbac,
  isDropdown = true,
  labelPathname,
  isExpandedSidebar = true,
  navThemeType = DASHBOARD_TYPE.BANK,
  openSidebar,
}: SidenavlinkProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const auAlert = useAlertBox();
  const [loading, setLoading] = useState<boolean>(false);
  const { isNTB, isQRCollection } = useAppSelector(
    (state: RootState) => state?.auDashboard
  );

  const profileData = useAppSelector((state) => state.profile?.data);

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resumeState, setResumeState] = useState<IPopUpData>(
    ntbStorePopupData.noData
  );
  const [leadSuccessModal, setLeadSuccessModal] = useState<{
    show: number;
    leadId: string;
  }>({
    show: 0,
    leadId: '',
  });
  const [leadGenLoading, setLeadGenLoading] = useState<boolean>(false);
  const [qrOnboardingModal, setQrOnboardingModal] = useState<boolean>(false);
  const customerAccountsRdx = useAppSelector(
    (state: RootState) => state.customerAccounts
  );
  const resAuthProfile = useAppSelector((state: RootState) => state.auth) as
    | AuthState
    | undefined;

  const resumeJourneyRdxData = useAppSelector(
    (state: RootState) => state.resumeJourneySlice
  ) as IResumeJourney | undefined;

  const checkIfCASAIsAvailable =
    customerAccountsRdx &&
    ((customerAccountsRdx?.data?.SavingsAccount?.length as number) > 0 ||
      (customerAccountsRdx?.data?.CurrentAccount?.length as number) > 0);

  const permissions = useAppSelector(
    (state) => state.profile.data?.permissions
  );
  const configRdx: any =
    useAppSelector((state: RootState) => state?.config?.data) ?? [];

  const crmLeadConfig: ICRMLeadConfig = getFEConfig(
    configRdx,
    CRMLEAD_CONFIG?.CONFIG_TYPE,
    CRMLEAD_CONFIG?.MODULE
  );
  const onboardingConfig: IOnboardingConfigData = getFEConfig(
    configRdx,
    ONBOARDING_CONFIG_KEY.ONBOARDING_CONFIG,
    ONBOARDING_CONFIG_KEY?.MODULE
  );

  const [accessibleOptionMenu, setAccessibleOptionMenu] = useState<
    any[] | undefined
  >([]);
  const isNTBAllowQRFlag = useAppSelector(
    (globalConfigState) => globalConfigState.init?.globalConfig?.isNTBAllowQR
  );
  const isETBAllowQRFlag = useAppSelector(
    (globalConfigState) => globalConfigState.init?.globalConfig?.isETBAllowQR
  );

  const accessibleOptionMenuAfter =
    permissions &&
    optionMenu?.filter((item) => {
      return item.rbac === '' || permissions?.includes(item?.rbac ?? '');
    });
  useEffect(
    () => permissions && setAccessibleOptionMenu(accessibleOptionMenuAfter),
    [permissions, optionMenu]
  );
  useEffect(() => {
    if (isExpandedSidebar) {
      setIsDropdownOpen(false);
    }
  }, [isExpandedSidebar]);

  const customerBasicDetails =
    customerAccountsRdx?.data?.CustomerAccount?.[0]?.CustomerBasicInquiry;

  const videoBankingRedirectionAPI = async (crossSellName: string) => {
    setLoading(true);
    const reqData = {
      crossSellName,
      PAYLOAD: {
        WORKFLOW_GROUP_ID: '',
        CHANNEL: appConstants.CHANNEL_MB,
        SUB_CHANNEL: '',
        CAMPAIGN_ID: '',
        PRODUCT: '',
        CHANNEL_REF_NO: '',
        PROSPECT_REF_NO: '',
        IS_MOBILE_NO_VERIFIED: 'Y',
        CUST_ID: CIF() as string,
        PROSPECT_DATA: {
          MOBILE_NO: customerBasicDetails?.MobileNumber ?? '',
          PAN_NUMBER: customerBasicDetails?.PAN ?? '',
          FIRST_NAME: customerBasicDetails?.CustomerName.FirstName ?? '',
          MIDDLE_NAME: '',
          LAST_NAME: customerBasicDetails?.CustomerName.LastName ?? '',
          PERMANENT_ADDRESS_LINE_1:
            customerBasicDetails?.CustomerAddress.Line1 ?? '',
          PERMANENT_ADDRESS_LINE_2:
            customerBasicDetails?.CustomerAddress.Line2 ?? '',
          PERMANENT_ADDRESS_LINE_3:
            customerBasicDetails?.CustomerAddress.Line3 ?? '',
          PERMANENT_PINCODE: customerBasicDetails?.CustomerAddress.Zip ?? '',
          PERMANENT_CITY: customerBasicDetails?.CustomerAddress.City ?? '',
          PERMANENT_STATE: customerBasicDetails?.CustomerAddress.State ?? '',
          COMM_ADDRESS_LINE_1:
            customerBasicDetails?.CustomerAddress.Line1 ?? '',
          COMM_ADDRESS_LINE_2:
            customerBasicDetails?.CustomerAddress.Line2 ?? '',
          COMM_ADDRESS_LINE_3:
            customerBasicDetails?.CustomerAddress.Line3 ?? '',
          COMM_PINCODE: customerBasicDetails?.CustomerAddress.Zip ?? '',
          COMM_CITY: customerBasicDetails?.CustomerAddress.City ?? '',
          COMM_STATE: customerBasicDetails?.CustomerAddress.State ?? '',
          COMM_COUNTRY: customerBasicDetails?.CustomerAddress.Country ?? '',
          DATE_OF_BIRTH: customerBasicDetails?.BirthDateText,
          EMAIL_ID: customerBasicDetails?.EmailAddress,
          GENDER: customerBasicDetails?.Sex,
        },
        OTHER_INFO: {
          FLEXIFIELD1: '',
        },
        CALLBACK_DETAILS: {
          RETURN_URL: '',
          CALLBACK_URL: '',
        },
      },
      CHECKSUM: '',
    } as IFetchLoanDetailsReq;
    try {
      const response: IFetchLoanDetailsResp | any =
        await fetchVideoBankingRedirection(reqData);
      if (response.successfulResponse) {
        redirectToUrl(response.data.url, {
          data: response.data.crossSellData,
          merchant: response.data.merchant,
        });
      } else {
        auAlert.error(response?.displayMessage ?? response);
      }
    } catch (error: any) {
      auAlert.error(error.getErrorMessage().toString());
    } finally {
      setLoading(false);
    }
  };

  const offersRedirectionAPI = async (crossSellName: string) => {
    setLoading(true);
    const reqData = {
      crossSellName,
      PAYLOAD: {
        WORKFLOW_GROUP_ID: '',
        CHANNEL: appConstants.CHANNEL_MB,
        SUB_CHANNEL: '',
        CAMPAIGN_ID: '',
        PRODUCT: '',
        CHANNEL_REF_NO: CHANNEL_REF_NO.MM,
        PROSPECT_REF_NO: '',
        IS_MOBILE_NO_VERIFIED: 'Y',
        CUST_ID: CIF() as string,
        PROSPECT_DATA: {
          MOBILE_NO: customerBasicDetails?.MobileNumber ?? '',
          PAN_NUMBER: customerBasicDetails?.PAN ?? '',
          FIRST_NAME: customerBasicDetails?.CustomerName.FirstName ?? '',
          MIDDLE_NAME: '',
          LAST_NAME: customerBasicDetails?.CustomerName.LastName ?? '',
          PERMANENT_ADDRESS_LINE_1:
            customerBasicDetails?.CustomerAddress.Line1 ?? '',
          PERMANENT_ADDRESS_LINE_2:
            customerBasicDetails?.CustomerAddress.Line2 ?? '',
          PERMANENT_ADDRESS_LINE_3:
            customerBasicDetails?.CustomerAddress.Line3 ?? '',
          PERMANENT_PINCODE: customerBasicDetails?.CustomerAddress.Zip ?? '',
          PERMANENT_CITY: customerBasicDetails?.CustomerAddress.City ?? '',
          PERMANENT_STATE: customerBasicDetails?.CustomerAddress.State ?? '',
          COMM_ADDRESS_LINE_1:
            customerBasicDetails?.CustomerAddress.Line1 ?? '',
          COMM_ADDRESS_LINE_2:
            customerBasicDetails?.CustomerAddress.Line2 ?? '',
          COMM_ADDRESS_LINE_3:
            customerBasicDetails?.CustomerAddress.Line3 ?? '',
          COMM_PINCODE: customerBasicDetails?.CustomerAddress.Zip ?? '',
          COMM_CITY: customerBasicDetails?.CustomerAddress.City ?? '',
          COMM_STATE: customerBasicDetails?.CustomerAddress.State ?? '',
          COMM_COUNTRY: customerBasicDetails?.CustomerAddress.Country ?? '',
          DATE_OF_BIRTH: customerBasicDetails?.BirthDateText,
          EMAIL_ID: customerBasicDetails?.EmailAddress,
          GENDER: customerBasicDetails?.Sex,
        },
        OTHER_INFO: {
          TYPE: appConstants.CUSTOMER_TYPE.ETB,
          FLEXIFIELD1: '',
          FLEXIFIELD2: '',
        },
        CALLBACK_DETAILS: {
          RETURN_URL: POSE_VINE_RETURN_URL,
          CALLBACK_URL: '',
        },
      },
      CHECKSUM: '',
    } as IFetchLoanDetailsReq;
    try {
      const response: IFetchLoanDetailsResp | any =
        await fetchOffersRedirection(reqData);
      if (response.successfulResponse) {
        redirectToUrl(response.data.url, {
          data: response.data.crossSellData,
          merchant: response.data.merchant,
        });
      } else {
        auAlert.error(response?.displayMessage ?? response);
      }
    } catch (error: any) {
      auAlert.error(error.getErrorMessage().toString());
    } finally {
      setLoading(false);
    }
  };

  const SCFRedirectionAPI = async () => {
    setLoading(true);

    try {
      const response = await fetchSCFURL();
      if (response.successfulResponse) {
        redirectToUrl(
          response.data.redirectionUrl.concat(response.data.edata),
          {
            data: response.data.edata,
            merchant: '',
          }
        );
      } else {
        auAlert.error(response?.displayMessage ?? response);
      }
    } catch (error: any) {
      auAlert.error(error.getErrorMessage().toString());
    } finally {
      setLoading(false);
    }
  };
  const getNtbStoreModalType = () => {
    if (resumeJourneyRdxData?.successfulResponse && resumeJourneyRdxData.data) {
      setResumeState(ntbStorePopupData.resumeApplication);
    } else if (
      resumeJourneyRdxData?.successfulResponse &&
      isEmpty(resumeJourneyRdxData?.data)
    ) {
      setResumeState(ntbStorePopupData.noData);
    } else if (resumeJourneyRdxData?.error) {
      const errorCode = resumeJourneyRdxData?.error
        ?.toString()
        .trim()
        .split(' : ')[0]
        .trim();

      if (errorCode === 'ONB0404') {
        setResumeState(ntbStorePopupData.noData);
      } else if (errorCode === 'ONB1041') {
        setResumeState(ntbStorePopupData.underReview);
      }
    }
  };

  const handleDropdownOpen = () => {
    if (navLinkPathArrays.storeLabelArray.includes(label ?? '')) {
      if (label === 'collections') {
        if (isQRCollection) {
          navigate('/qr-collection/qr-collection-detail');
          openSidebar?.(false);
        } else {
          getNtbStoreModalType();
          setIsModalOpen(true);
        }
      } else {
        if (isQRCollection) {
          navigate('/qr-settlement');
          openSidebar?.(false);
        } else {
          getNtbStoreModalType();
          setIsModalOpen(true);
        }
      }
    } else {
      isDropdown && !isExpandedSidebar && setIsDropdownOpen((prev) => !prev);
      if (labelPathname) {
        changeTheme();
        navigate(labelPathname);
        openSidebar?.(false);
      }
    }
  };

  const getActiveLoans = () => {
    return customerAccountFilter(
      customerAccountsRdx?.data,
      [ACCOUNT_TYPES.Loans.code],
      ['paymentactive']
    );
  };

  const handleRoutes = (pathname) => {
    if (
      navLinkPathArrays.pathArray.includes(pathname) &&
      !checkIfCASAIsAvailable
    ) {
      setIsModalOpen(true);
    } else if (pathname === '/loans' && getActiveLoans()?.length === 0) {
      openSidebar?.(false);
      navigate('/loans/apply-for-loans', {
        state: { backOnLoanDashboard: false },
      });
    } else if (navLinkPathArrays.ntbPathArray.includes(pathname) && isNTB) {
      setIsModalOpen(true);
    } else if (
      (pathname === '/loanstatement' && isNTB) ||
      (pathname === '/loanstatement' &&
        !isNTB &&
        getActiveLoans()?.length === 0)
    ) {
      navigate('/loans/apply-for-loans', {
        state: { backOnLoanDashboard: false },
      });
      openSidebar?.(false);
    } else if (pathname === '/video-banking') {
      if (isNTB) {
        window?.open(
          t('NTB_VIDEO_BANKING_URL'),
          '_blank',
          'noopener,noreferrer'
        );
      } else {
        videoBankingRedirectionAPI(CROSS_SELL_NAME.VIDEO_BANKING);
      }
    } else {
      openSidebar?.(false);
      navigate(pathname);
    }
  };

  const changeTheme = () => {
    const themeType = getNavThemeFromLocal();
    if (navThemeType !== themeType) {
      setNavThemeType(navThemeType);
      setTimeout(() => {
        dispatch(setNavThemeTypeRdx(navThemeType));
      }, 50);
    }
  };

  const getEntityCif = () => {
    const activeMerchantId = profileData?.activeMerchantId ?? '';

    return {
      cif:
        profileData?.merchants?.[activeMerchantId]?.entityCif ??
        profileData?.merchants?.[activeMerchantId]?.userCif ??
        '',
      isInd:
        profileData?.merchants?.[activeMerchantId]?.constitutionTypeCode ===
        appConstants.CONSTITUTION_TYPE.IND,
    };
  };

  const setCifStatus = (flagToSet) => {
    dispatch(
      setCifStaffStatusData({
        cifStaffStatusFlag: flagToSet,
      })
    );
    navigate(QR_ONBOARDING_NAV);
  };

  const checkAuEmployeeApi = async () => {
    try {
      const response = await checkIsAuEmployee({
        CustomerId: getEntityCif().cif,
      });

      if (response?.successfulResponse) {
        if (response?.data?.StaffFlag === FLAG?.Y) {
          if (!onboardingConfig?.isAuEmployeeOnboardingAllowed) {
            setQrOnboardingModal(true);
          } else {
            setCifStatus(cifStaffStatusFlags?.YES);
          }
        } else {
          setCifStatus(cifStaffStatusFlags?.NO);
        }
      } else {
        setCifStatus(cifStaffStatusFlags?.NOT_VERIFIED);
      }
    } catch (error: any) {
      setCifStatus(cifStaffStatusFlags?.NOT_VERIFIED);
    } finally {
      setLeadGenLoading(false);
      setIsModalOpen(false);
    }
  };

  const crmLeadApiCall = async (userType: string) => {
    const ntbQrLayoutId = crmLeadConfig?.layoutId?.isNtbQrAllowed;
    const ntbQrProductId = crmLeadConfig?.productId?.isNtbQrAllowed;
    const ntbQrproductCategoryId =
      crmLeadConfig?.productCategoryId?.isNtbQrAllowed;

    const reqObj = {
      LayoutId: ntbQrLayoutId ?? '',
      name: resAuthProfile?.name,
      DateOfBirth: '',
      PanNumber: '',
      dob: '',
      ProductCategoryID: ntbQrproductCategoryId ?? '',
      ProductID: ntbQrProductId ?? '',
      MobilePhone: resAuthProfile?.mobileNumber,
      StampCustName: resAuthProfile?.name,
      country: '',
      cif: '',
      TypeofCustomerSA: userType ?? TYPE_OF_CUSTOMER?.NTB,
      CurrentState: '',
      ShortName: '',
      CurrentCity: '',
      TerritoryID: '',
      CurrentAddressLine1: '',
      CurrentAddressLine2: '',
      CurrentAddressLine3: '',
      Email: resAuthProfile?.emailAddress,
      LeadSource: LEAD_SOURCE,
    };
    try {
      setLeadGenLoading(true);
      const crmLeadData = await dispatch(crmLeadGen(reqObj));
      if (crmLeadData?.payload?.successfulResponse) {
        setLeadSuccessModal({
          show: 1,
          leadId: crmLeadData?.payload?.data?.LeadID,
        });
      } else {
        setLeadSuccessModal({
          show: 2,
          leadId: crmLeadData?.payload?.requestId,
        });
      }
    } catch (error: any) {
      setLeadSuccessModal({
        show: 2,
        leadId: error?.requestId,
      });
    } finally {
      setLeadGenLoading(false);
      setIsModalOpen(false);
    }
  };

  const handleApplyQr = () => {
    if (isNTB) {
      if (!isNTBAllowQRFlag) {
        crmLeadApiCall(TYPE_OF_CUSTOMER?.NTB);
      } else {
        navigate(QR_ONBOARDING_NAV);
        setIsModalOpen(false);
        setLeadGenLoading(false);
      }
    } else {
      if (!isETBAllowQRFlag) {
        crmLeadApiCall(TYPE_OF_CUSTOMER?.ETB);
      } else {
        if (getEntityCif().isInd) {
          checkAuEmployeeApi();
        } else {
          navigate(QR_ONBOARDING_NAV);
        }
      }
    }
  };
  const auOverviewList = useAppSelector(
    (state) => state?.auOverviewList?.overviewCardList
  );
  const renderModal = () => {
    if (navLinkPathArrays.storeLabelArray.includes(label ?? '')) {
      return (
        <XB_Modal
          open={isModalOpen}
          setOpen={(val) => setIsModalOpen(val)}
          title={t(resumeState.title)}
          subTitle={t(resumeState.subtitle ?? '')}
          img={
            <AU_RoundBox boxClass="bg-orange-50">
              <Icon
                width={48}
                height={48}
                className="text-orange-600"
                icon="alerttriangle"
              />
            </AU_RoundBox>
          }
          body={
            resumeState.body && (
              <div className="w-full">
                <img src={ApplyForQr} alt="" />
              </div>
            )
          }
          actions={
            <div className="flex flex-col w-full gap-2">
              {resumeState.btn1 && (
                <XB_Button
                  dataTestId={resumeState.btn1Id}
                  onClick={() => {
                    if (resumeState.navigateToApplication) {
                      setLeadGenLoading(true);
                      handleApplyQr();
                    }
                  }}
                  showLoader={leadGenLoading}
                >
                  {t(resumeState.btn1)}
                </XB_Button>
              )}
              {resumeState.btn2 && resumeState.btn2Id && (
                <XB_Button
                  dataTestId={resumeState.btn2Id}
                  onClick={() => setIsModalOpen(false)}
                >
                  {t(resumeState.btn2)}
                </XB_Button>
              )}
            </div>
          }
        />
      );
    } else {
      return (
        <Form15ErrorModal
          isOpen={isModalOpen}
          setOpen={(val) => setIsModalOpen(val)}
          title="youDontHaveOperatingAccLbl"
          btnProps={{
            dataTestId: 'OPEN-CRNT-ACCT',
            children: t('openCurrentAc'),
            onClick: () => {
              setIsModalOpen(false);
              window.open(t('CAUrl'), '_blank', 'noopener,noreferrer');
            },
          }}
          errorImage={auOverviewList?.[0]?.bannerImg}
        />
      );
    }
  };

  const handleApplyQrTryAgain = () => {
    if (isNTB) {
      if (!isNTBAllowQRFlag) {
        crmLeadApiCall(TYPE_OF_CUSTOMER?.NTB);
      }
    } else {
      if (!isETBAllowQRFlag) {
        crmLeadApiCall(TYPE_OF_CUSTOMER?.ETB);
      }
    }
  };

  const renderCRMLeadModal = () => {
    return (
      <CrmLeadSuccessModal
        isOpen={leadSuccessModal?.show !== 0}
        setOpen={() =>
          setLeadSuccessModal({
            ...leadSuccessModal,
            show: 0,
          })
        }
        data={{
          emailAddress: resAuthProfile?.emailAddress ?? '',
          mobileNumber: resAuthProfile?.mobileNumber ?? '',
          refNo: leadSuccessModal?.leadId,
        }}
        isSuccess={leadSuccessModal?.show === 1}
        tryAgainFn={() => {
          handleApplyQrTryAgain();
        }}
      />
    );
  };

  const handleLabelClick = (labelname, rbacId) => {
    if (permissions?.includes(rbacId ?? '')) {
      if (labelname === 'videoBanking') {
        if (isNTB) {
          window.open(t('NTB_VIDEO_BANKING_URL'), '_blank');
        } else {
          videoBankingRedirectionAPI(CROSS_SELL_NAME.VIDEO_BANKING);
        }
      } else if (labelname === 'offer') {
        offersRedirectionAPI(CROSS_SELL_NAME.POSH_VINE);
      } else if (labelname === 'supplyChainFinance') {
        SCFRedirectionAPI();
      }
    } else {
      if (labelname === 'videoBanking') {
        if (rbacId) {
          auAlert.error(t('STMT0403'));
        } else {
          if (isNTB) {
            window.open(t('NTB_VIDEO_BANKING_URL'), '_blank');
          } else {
            videoBankingRedirectionAPI(CROSS_SELL_NAME.VIDEO_BANKING);
          }
        }
      } else if (labelname === 'offer') {
        if (rbacId) {
          auAlert.error(t('STMT0403'));
        } else {
          offersRedirectionAPI(CROSS_SELL_NAME.POSH_VINE);
        }
      } else if (labelname === 'supplyChainFinance') {
        if (rbacId) {
          auAlert.error(t('STMT0403'));
        } else {
          SCFRedirectionAPI();
        }
      }
    }
  };

  const renderNavLink = () => (
    <div
      className={`${
        location.pathname === labelPathname
          ? 'bg-primary-100 hover:bg-primary-100'
          : ''
      } navlink_container cursor-pointer`}
      onClick={() => {
        handleDropdownOpen();
      }}
    >
      <div className="navlink_dropdown_container">
        {prefixIconName && (
          <div onClick={() => handleLabelClick(label, rbac)}>
            <Icon
              icon={prefixIconName}
              color="none"
              className={`${
                location.pathname === labelPathname
                  ? 'text-orange-600'
                  : 'text-gray-600'
              } m-text-lg-medium  ml-3`}
            />
          </div>
        )}
        {!isExpandedSidebar && (
          <h1
            className={`${
              location.pathname === labelPathname
                ? 'text-orange-600'
                : 'text-gray-600'
            } m-text-lg-medium  ml-3`}
            onClick={() => handleLabelClick(label, rbac)}
          >
            {t(`${label}`)}
          </h1>
        )}
        {suffixIconName && isDropdown && !isExpandedSidebar && (
          <Icon
            icon={suffixIconName}
            color="none"
            width={20}
            height={20}
            className={`icon-class-rotate-${
              isDropdownOpen ? 'up' : 'down'
            } ml-auto`}
          />
        )}
      </div>
    </div>
  );

  const showNavlink = () => {
    let ele: JSX.Element | string = '';
    if (isDropdown) {
      if (!isEmpty(accessibleOptionMenu)) {
        ele = renderNavLink();
      } else {
        ele = '';
      }
    } else {
      ele = renderNavLink();
    }
    return ele;
  };

  return (
    <section>
      {leadSuccessModal?.show !== 0 && renderCRMLeadModal()}
      <QrOnboardingNotAllowedModal
        isOpen={qrOnboardingModal}
        setOpen={(open) => setQrOnboardingModal(open)}
        callBack={() => {
          setQrOnboardingModal(false);
        }}
      />
      {loading && <AU_Loader fullLoader={true} />}
      {showNavlink()}
      {isDropdownOpen && isDropdown && (
        <>
          {accessibleOptionMenu?.map((ele, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  handleRoutes(ele?.pathname);
                }}
                className={`${
                  location.pathname === ele.pathname
                    ? 'bg-orange-100 hover:bg-orange-100 '
                    : ''
                } flex items-center dropdown_list cursor-pointer`}
              >
                {ele.optionMenuIcon && isExpandedSidebar && (
                  <Icon
                    icon={ele.optionMenuIcon}
                    color={`${
                      location.pathname === ele.pathname
                        ? 'var(--primary-600)'
                        : 'none'
                    }`}
                    width={20}
                    height={20}
                  />
                )}
                {!isExpandedSidebar && (
                  <p
                    className={`m-text-lg-medium ${
                      location.pathname === ele.pathname
                        ? 'text-primary-600'
                        : 'text-neutral-600'
                    } `}
                    onClick={() => changeTheme()}
                  >
                    {t(ele.title)}
                  </p>
                )}
              </div>
            );
          })}
        </>
      )}
      {renderModal()}
    </section>
  );
};
