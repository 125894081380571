import { statementNavigation } from '@src/modules/au-dashboard/constants/au-dashboard.constant';

export const dashboard_urls = {
  mr_dashboard: '/mr-dashboard',
};
export const payment_urls = {
  main: '/payments',
  money_transfer: 'moneytransfer',
  transfer_to_payee: 'transfer-to-payee',
  transfer_to_self: 'transfer-to-self',
  quick_acc_transfer: 'quick-acc-transfer',
  multiple_payment: 'multiple-payments',
  transaction_status: '/payments/transaction-status',
  back_to_money_transfer: '/payments/moneytransfer',
  add_payment: 'add-payment',
  add_mupltiple_payment: 'multiple-payments/add-payment',
  to_multiple_payment: '/payments/multiple-payments',
  payments_tnsfr_payee: '/payments/transfer-to-payee',
  gst_payment: '/payments/gst',
  to_quick_tnsfr: '/payments/quick-acc-transfer',
  to_tnsfr_to_payee: '/payments/transfer-to-payee',
  to_tnsfr_to_self: '/payments/transfer-to-self',
  schedule_txn_status: '/payments/schedule-transaction-status',
  scheduled_payment_detail: 'scheduled-payment-detail',
  to_scheduled_payment_detail: '/payments/scheduled-payment-detail',
  multiple_payment_txn_status:
    '/payments/multiple-payments/multiple-payment-txn-status',
};

export const credit_card_urls = {
  main: '/credit-card',
  replace_status: 'replace-status',
  replace_card: 'replace-card',
  autopay: 'autopay',
  autopay_status: 'autopay-status',
  pay_bill: 'pay-credit-card-bill',
  select_payment_service: 'select-payment-service',
  rewards_summary: 'rewards-summary',
  to_rewards_summary: '/credit-card/rewards-summary',
  statement: '/credit-card/card-statement',
  emi_eligible_txns: '/credit-card/emi-eligible-transactions',
  express_emi: '/credit-card/emi-eligible-transactions/express-emi',
  setpin: '/credit-card/setpin',
  status: 'status',
  block_card: 'block-card',
  to_block_card: '/credit-card/block-card',
  block_card_status: 'block-card-status',
  to_block_card_status: '/credit-card/block-card-status',
  to_pay_bill: '/credit-card/pay-credit-card-bill',
  card_usage: 'card-usage',
  to_card_summary: '/credit-card/card-summary',
  to_card_usage: '/credit-card/card-usage',
  to_replace_status: '/credit-card/replace-status',
  to_replace_card: '/credit-card/replace-card',
  to_autopay: '/credit-card/autopay',
  to_autopay_status: '/credit-card/autopay-status',
  to_select_payment_service: '/credit-card/select-payment-service',
  card_summary: 'card-summary',
};

export const payee_urls = {
  to_add_payee: '/payees/add',
  to_payee_list: '/payees',
};
export const loans_urls = {
  main: '/loans',
  pay_overdue: 'loan-details/pay-overdue',
  apply_for_loans: '/loans/apply-for-loans',
  loan_details: 'loan-details',
  loan_payment_status: 'payment-status',
  payment_status: 'loan-details/pay-overdue/payment-status',
  apply_home_loans: '/loans/apply-for-home-loans',
  apply_home_loans_success: '/loans/success',
};

export const account_services_urls = {
  account_details: '/accountsummary/details',
  statement: statementNavigation,
  account_summary: '/accountsummary/home',
  account_interest_cert: '/accountsummary/account-interest-certificate',
};

export const debit_card_urls = {
  debit_card: '/debit-card',
};

export const service_request_urls = {
  home: '/service-request',
  main: '/service-request-main',
  new_check_book: 'new-cheque-book',
  cheque_status: 'cheque-status',
  stop_cheque: 'stop-cheque',
  new_chequebook_address_update:
    '/service-request/new-cheque-book/update-communication-address',
  stop_cheque_status: '/service-request/stop-cheque/status',
  positive_pay: '/service-request/positive-pay',
  positive_pay_review: '/service-request/positive-pay/positive-pay-review',
  positive_pay_status: '/service-request/positive-pay/positive-pay-status',
  update_communication_address: '/service-request/update-communication-address',
  form15_details: '/service-request/form-15g-h/details',
  form_15gh: 'form-15g-h',
  form_15ghnavigate: '/service-request/form-15g-h/',
  form15_status: '/service-request/form-15g-h/details/status',
  money_transfer: '/payments/moneytransfer',
  track_service_req: 'track-service-req',
  track_service_review: 'track-service-review',
  track_service_req_list: '/service-request/track-service-req-list',
  track_service_request: 'track-service-request',
  service_request_list:
    '/service-request/track-service-request/service-request-list',
  reference_details:
    '/service-request/track-service-request/service-request-list/reference-details',
  account_nominee: 'account-nominee',
  update_email: '/service-request/update-email-id',
  update_email_status: '/service-request/update-email-id/status',
  new_cheque_status: '/service-request/new-cheque-book/new-cheque-status',
  positive_pay_home: 'positive-pay-status',
  positive_pay_info: '/service-request/positive-pay-info',
  to_positive_pay_status: '/service-request/positive-pay-status',
  update_address_status: '/service-request/update-address-status',
  redo_kyc: '/service-request/redo-kyc',
  acc_interest_certificate: '/service-request/account-interest-certificate',
};

export const inventory_request_urls = {
  send_for_printing: '/send-for-printing',
  customer_request: '/customer-request',
  allocation_report: '/allocation-report',
  generate_vpa: '/generate-vpa',
  printing_status: '/printing-status',
  allocation_history: '/allocation-history',
  vertical_allocation: '/vertical-allocation',
};

export const link_payee_urls = {
  set_alwc: 'set-allowance',
  home: '/link-payee',
  details: '/link-payee/details',
  status: '/link-payee/details/withdraw-status',
  link_payee_status: '/link-payee/status',
};

export const apply_now_urls = {
  apply_now: '/apply-now',
  apply_for_locker: '/apply-now/locker',
  business_banking: '/apply-now/business-banking',
  apply_for_locker_status: '/apply-now/locker/locker-status',
  business_banking_status: '/apply-now/business-banking/status',
};

export const so_urls = {
  home: '/home',
  so_dashboard: '/so-dashboard',
  profile_dashboard: '/dashboard',
  raise_request: '/so-dashboard/raise-requests',
  so_request_history: '/so-request-history',
  so_past_allotment: '/so-past-allotment',
  so_past_allotment_detail: '/so-past-allotment/detail',
  so_language: '/setting/language',
  so_notification: '/setting/notification',
  so_setting: '/setting',
};
export const bm_urls = {
  home: '',
  bm_view_report: '/view-report',
  bm_full_report: '/full-report',
  bm_request_history: '/bm-request-history',
  bm_allocation_history: 'allocation-history',
  bm_allocation: '/bm-allocation',
  bm_past_allotment: 'bm-past-allotment',
  bm_past_allotment_detail: '/bm-past-allotment-detail',
  bm_customer_request_list: 'bm-customer-requests',
  bm_raise_request: '/bm-raise-request',
  bm_dashboard: '/bm-dashboard',
};

export const investment_urls = {
  main: 'investment',
};

export const insurance_urls = {
  main: 'insurance',
};

export const entitlement_urls = {
  roles_list: '/entitlement/employee/roles-list',
  create_role: '/entitlement/employee/create-role',
  clone_role: '/entitlement/employee/clone-role',
  modify_role: '/entitlement/employee/modify-role',
  create_user: '/entitlement/employee/create-users',
  modify_user: '/entitlement/employee/modify-users',
  create_user_details: '/entitlement/employee/create-users-details',
  modify_user_details: '/entitlement/employee/modify-users-details',
  role_details: '/entitlement/employee/role-details',
  users: '/entitlement/employee/users',
  user_auth_group_list: '/entitlement/employee/user-auth-group-list',
  user_details: '/entitlement/employee/user-details',
  user_group_details: '/entitlement/employee/user-group-details',
  auth_group_details: '/entitlement/employee/auth-group-details',
  // create_user_auth_group_list: '/entitlement/employee/create-group',
  modify_user_auth_group: '/entitlement/employee/modify-group',
  create_user_auth_group: '/entitlement/employee/add-group',
  mr_role_list: '/entitlement/merchant/roles-list',
  mr_create_role: '/entitlement/merchant/create-role',
  mr_role_details: '/entitlement/merchant/role-details',
  mr_modify_role: '/entitlement/merchant/modify-role',
  mr_clone_role: '/entitlement/merchant/clone-role',
  mr_auth_details: '/entitlement/auth/details',
  mr_auth_list: '/entitlement/auth/list',
  mr_list: '/entitlement/merchant/merchant-list',
  mr_user_details: '/entitlement/merchant/user-details',
  mr_user_details_user_add: '/entitlement/merchant/add-user',
  mr_user_details_confirmation: '/entitlement/merchant/mr-user-details-confirm',
  mr_user_account_map: '/entitlement/merchant/mr-user-account-map',
  mr_user_role_map: '/entitlement/merchant/mr-user-role-map',
  mr_user_auth_group_map: '/entitlement/merchant/mr-user-auth-group-map',
  mr_view: '/entitlement/merchant/view',
  mr_details: '/entitlement/merchant/details',
  mr_user_creation_review: '/entitlement/merchant/mr-user-creation-review',
  merchant_user_details: '/entitlement/merchant/mr-user-details',
};

export const limitManagement_urls = {
  dashboard: '/limit-management/dashboard',
  merchant_details: '/limit-management/merchant-detail',
  merchant_details_edit: '/limit-management/edit-detail',
};

export const settlementAdmin_urls = {
  report_download: '/settlement-management/report-download',
  status_file_upload: '/settlement-management/status-file-upload',
  report_search: '/settlement-management/report-search',
  txn_status_update: '/settlement-management/txn-status-update',
};

export const profile_setting_urls = {
  store_detail: '/profile/store-detail',
  profile_setting: '/profile/profile-setting',
  device_block_unblock: '/profile/manage-devices',
  inactive_devices: '/profile/inactive-devices',
  settings: '/profile/home',
  support: '/profile/support',
  faq: '/profile/faq',
  faq_questions: '/profile/faq-questions',
  faq_answers: '/profile/faq-answer',
  user_auth_ntb: '/profile/change-password/user-authentication',
  user_auth_etb: '/profile/change-password/verify-yourself',
  change_pswd: '/profile/change-password',
  user_authentication: '/profile/user-authentication',
  notification_settings: '/profile/notification-settings',
  profile_about: '/profile/about',
  update_username: '/profile/update-username',
  report_issue: '/profile/report-issue',
  confirm_block: '/profile/confirm-block',
  fraud_dispute_status: '/profile/fraud-dispute-status',
  report_transaction: '/profile/report-transaction',
  cif_addition: '/profile/cif-addition',
  about_us: '/profile/about-us',
  set_default_home: '/profile/set-default-home',
};

export const settlement_urls = {
  settlement_settings: '/settlement/settings',
  add_new_bank_acc: '/settlement/add-new-bank-acc',
  confirm_bank_acc: '/settlement/confirm-bank-acc-details',
  forgot_pswd: '/forgot-password',
  link_new_au_acc: '/settlement/link-bank-acc-details',
  settings_page: '/profile/home',
};

export const appConfigurationAdmin_urls = {
  templates: '/app-configuration/templates',
};
export const limit_mr_urls = {
  set_global_future_limit: '/limit-management/set-global-future-limit',
  set_individual_future_limit: '/limit-management/set-individual-future-limit',
  set_user_future_limit: '/limit-management/set-user-future-limit',
};
