import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AU_Loader, Icon, XB_Modal } from '@core-components/atoms';
import { XB_VerifyOtp } from '@core-components/organisms';
import { OTPEventType, OTPJourneyType } from '@core-constants';
import { useAlertBox } from '@core-hooks';
import { OTPSend, sendOtp } from '@core-services';
import { getNCardNumber, isEmpty } from '@core-utils';

interface CommonOtpModalProps {
  mobileNumber: string;
  email?: string;
  customerId?: string;
  otpJourney?: OTPJourneyType;
  otpModal: boolean;
  setOtpModal: (checked: boolean) => void;
  onOtpVerified: () => void;
  eventType?: OTPEventType;
  payeeName?: string;
  txnAmount?: string;
  modeOfPayment?: string;
  debitCardNo?: string;
  accHolderName?: string;
  onClickCloseModal?: () => void;
  setOTPLoading?: (loading: boolean) => void;
}

export const AU_CommonOtpModal = ({
  mobileNumber,
  email,
  customerId,
  otpJourney = OTPJourneyType.PRE_REGISTRATION,
  otpModal,
  setOtpModal,
  onOtpVerified,
  eventType = OTPEventType.PRE_REGISTRATION_APP_OTP_SMS,
  payeeName = '',
  txnAmount = '',
  modeOfPayment = '',
  debitCardNo = '',
  accHolderName = '',
  onClickCloseModal,
  setOTPLoading,
}: CommonOtpModalProps) => {
  const { t } = useTranslation();

  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [resendTimer, setResendTimer] = useState<number>(20);
  const [expireTimer, setExpireTimer] = useState<number>(30);
  const [otpId, setOtpId] = useState<string>('');
  const auAlert = useAlertBox();
  const sendOtpCall = async () => {
    try {
      const otpObject: OTPSend = {
        mobileNumber,
        eventType,
        payeeName,
        txnAmount,
        modeOfPayment,
        debitCardNo: getNCardNumber(debitCardNo, 4) ?? '',
        accHolderName,
      };
      email && (otpObject.customerId = customerId);
      const response = await sendOtp(otpObject, otpJourney);
      if (response?.status === 'success') {
        const { otpResendSeconds, otpExpiredSeconds } = response.data;
        setExpireTimer(otpExpiredSeconds);
        setResendTimer(otpResendSeconds);
        setOtpId(response?.data?.otpId);
        setShowLoading(false);
        if (setOTPLoading) setOTPLoading(false);
      }
      if (response.status === 'error') {
        setShowLoading(false);
        if (setOTPLoading) setOTPLoading(false);
        setOtpModal(false);
      }
    } catch (error: any) {
      auAlert.error(error?.displayMessage);
      setShowLoading(false);
      if (setOTPLoading) setOTPLoading(false);
      setOtpModal(false);
    } finally {
      setShowLoading(false);
      if (setOTPLoading) setOTPLoading(false);
    }
  };

  useEffect(() => {
    if (setOTPLoading) setOTPLoading(true);
    sendOtpCall();
  }, []);

  return (
    <div>
      {showLoading ? (
        <AU_Loader fullLoader={true} />
      ) : (
        !isEmpty(otpId) && (
          <XB_Modal
            headPos="text-left"
            backdropClose={false}
            img={
              <div className="flex justify-between">
                <Icon icon="mobile" color="none" width={40} height={40} />
                <span
                  className="close--icon text-xs cursor-pointer"
                  onClick={() =>
                    onClickCloseModal ? onClickCloseModal() : setOtpModal(false)
                  }
                >
                  <Icon icon="close" width={16} height={16} />
                </span>
              </div>
            }
            open={otpModal}
            setOpen={(open) => setOtpModal(open)}
            maxWidth="408px"
            title={t('enterVerificationCode')}
            subTitle={t('sentCodeTo', {
              number: `xxxxxx${mobileNumber.trim().slice(-4)}`,
            })}
            actions={<div className="flex gap-2 justify-around" />}
            body={
              <XB_VerifyOtp
                id={''}
                otpId={otpId}
                otpLabel={''}
                mobileNumber={mobileNumber}
                email={email}
                resendTimer={resendTimer}
                expiryTimer={expireTimer}
                onOtpVerified={onOtpVerified}
                otpJourney={otpJourney}
                height="auto"
                onOtpError={function (): void {
                  throw new Error('Function not implemented.');
                }}
              />
            }
          />
        )
      )}
    </div>
  );
};
