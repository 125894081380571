import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  AU_SideDrawer,
  HeadingBar,
  Icon,
  XB_Button,
  XB_Divider,
  XB_Input,
  XB_Modal,
  XB_SectionInfo,
} from '@core-components/atoms';
import { useAlertBox } from '@core-hooks';
import {
  logout,
  RootState,
  useAppDispatch,
  useAppSelector,
} from '@core-stores';
import { getConcatenatedString, getFirstLastChar } from '@core-utils';
import InactivityDetector from '@modules/common/components/inactivity-detector.component';

import Au_bank_image from '@src/assets/images/AuLogo.png';

import './AU_AdminTopNavbar.style.scss';

export const AU_AdminTopNavbar = ({ onRoot }: { onRoot?: () => void }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auAlert = useAlertBox();
  const dispatch = useAppDispatch();
  const onClickHamburger = () => {
    const ele: any = document.getElementById('admin_navbar_container');
    if (ele) {
      ele.classList.toggle('--open');
    }
  };
  const { globalConfig } = useAppSelector((state: RootState) => state.init);
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [enableLoader, setEnableLoader] = useState(false);
  const [notificationsSideBar, setNotificationsSideBar] =
    useState<boolean>(false);
  const apiLogout = async (forced = false) => {
    setEnableLoader(true);
    const apiHeader = {
      sessionId: sessionStorage.getItem('sessionId') ?? '',
    };

    const response = dispatch(logout(apiHeader));
    response
      .unwrap()
      .then(async (_resp) => {
        if (forced) {
          auAlert.warning(t('dueToInactivityLoggedOut').toString());
        } else {
          auAlert.success(t('logoutSuccessfully').toString());
        }
        navigate('/');
        sessionStorage.clear();
        localStorage.clear();
        const Cookies = document.cookie.split(';');
        for (const cookie of Cookies) {
          document.cookie = cookie + '=; expires=' + new Date(0).toUTCString();
        }
      })
      .catch((err) => {
        auAlert.error(err?.displayMessage);
        setEnableLoader(false);
      });
  };
  const profileData = useAppSelector((state: RootState) => state.profile);
  const handleClickLogout = () => {
    setEnableLoader(true);
    apiLogout();
  };
  const confirmationModal = useMemo(
    () => (
      <>
        <XB_Modal
          headPos="text-left"
          title={t('logout')}
          subTitle={t('logOutMessage')}
          maxWidth="25rem"
          open={openConfirmModal}
          setOpen={setOpenConfirmModal}
          img={<Icon icon="warningmodal" height={48} width={48} color="none" />}
          actions={
            <div className="flex">
              <XB_Button
                dataTestId="NO"
                btnType="secondary-gray"
                classes="w-1/2 mr-2"
                onClick={() => {
                  setOpenConfirmModal(false);
                }}
              >
                {t('cancel')}
              </XB_Button>
              <XB_Button
                dataTestId="YES"
                btnType="primary"
                classes="w-1/2 ml-2"
                showLoader={enableLoader}
                onClick={() => {
                  handleClickLogout();
                }}
              >
                {t('logout')}
              </XB_Button>
            </div>
          }
        />
      </>
    ),
    [openConfirmModal]
  );
  const mockNotifs = ['1', '2', '3', '4', '5', '6', '7'];

  const showNotificationSideBar = () => {
    return (
      <AU_SideDrawer
        classes="z-20"
        open={notificationsSideBar}
        header={
          <HeadingBar
            isGrayHeader={true}
            onClose={() => {
              setNotificationsSideBar(false);
            }}
          >
            <XB_SectionInfo
              title={t('notifications')}
              classes="m-display-sm-bold text-neutral-700"
            />
          </HeadingBar>
        }
      >
        <div>
          {mockNotifs.map((notif, index: number) => {
            return (
              <Fragment key={getConcatenatedString([index, notif])}>
                {index !== mockNotifs.length - 1 && (
                  <XB_Divider classes="py-3" />
                )}
              </Fragment>
            );
          })}
        </div>
      </AU_SideDrawer>
    );
  };
  const NameInitialsUI = () => {
    return getFirstLastChar((profileData.data?.name as string) ?? '');
  };
  const CustomerNameUI = () => {
    return profileData.data?.name as string;
  };
  const handleModalClick = () => {
    setShowProfileModal((prev) => !prev);
  };
  const useOutsideClick = (callback: () => void) => {
    const clickRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          clickRef.current &&
          !clickRef.current.contains(event.target as Node)
        ) {
          callback();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [callback]);

    return clickRef;
  };

  const ref = useOutsideClick(() => {
    setShowProfileModal(false);
  });
  const goToHome = () => {
    if (onRoot) {
      onRoot();
    } else {
      navigate('/');
    }
  };
  return (
    <div className="admin_nav_container">
      {confirmationModal}
      {showNotificationSideBar()}

      <InactivityDetector
        timeout={parseInt(globalConfig?.webUserInactivityTime)}
        onInactive={() => {
          setEnableLoader(true);
          apiLogout(true);
        }}
      />
      <div className="nav_left_container">
        <div
          className="w-[3.5rem] h-[3.5rem] cursor-pointer"
          onClick={goToHome}
        >
          <img src={Au_bank_image} className="rounded" alt="" />
        </div>
        <span onClick={onClickHamburger}>
          <Icon icon="hamburger" className="text-white hamburger-icon" />
        </span>

        <XB_Input
          id="SRCH"
          placeholder={t('search')}
          prefixIcon="search"
          classes="ml-12 w-[500px] search-bar"
        />
      </div>
      <div className="right_content">
        <div className="list_wrapper gap-8">
          {/* 
         Intensionally Commented

         <li className="cursor-pointer support-icon">
            <Icon icon="help-circle" color="none" />
          </li>
          <li
            className="cursor-pointer"
            onClick={() => {
              setNotificationsSideBar(true);
            }}
          >
            <Icon icon="bell" color="none" />
          </li> */}
          <li
            className="cursor-pointer flex gap-2"
            onClick={() => {
              handleModalClick();
            }}
          >
            <span className="name_box align-middle m-display-sm-medium text-primary-600 bg-white  !hidden sm:!flex">
              <NameInitialsUI />
            </span>

            <Icon icon="chevron-down" className="text-white self-center" />
          </li>
        </div>
        {showProfileModal && (
          <div className="profile_wrapper" ref={ref}>
            <div className="flex profile_box">
              <div className="inner_wrapper">
                <span className="name_box m-display-sm-medium text-primary-600">
                  {NameInitialsUI()}
                </span>
                <span className="ml-1 mt-auto mb-auto">
                  <h1 className="m-text-md-bold text-gray-700">
                    {CustomerNameUI()}
                  </h1>
                  <span className="m-text-sm-regular text-gray-500">
                    {`Emp ID - ${profileData.data?.employeeId}`}
                  </span>
                </span>
              </div>
            </div>
            <XB_Divider classes="mt-2 w-full" />
            <div className="flex flex-col gap-1">
              <p className="text-gray-700 m-text-sm-regular">{t('role')}</p>
              <p className="text-gray-900 m-text-md-medium">
                {profileData.data?.roles.length
                  ? profileData.data?.roles[0].name
                  : ''}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-gray-700 m-text-sm-regular">
                {t('reportingTo')}
              </p>
              <p className="text-gray-900 m-text-md-medium">-</p>
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-gray-700 m-text-sm-regular">{t('emailId')}</p>
              <p className="text-gray-900 m-text-md-medium">
                {profileData.data?.emailAddress}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-gray-700 m-text-sm-regular">
                {t('mobileNumber')}
              </p>
              <p className="text-gray-900 m-text-md-medium">
                {profileData.data?.mobileNumber}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-gray-700 m-text-sm-regular">
                {t('officeLocation')}
              </p>
              <p className="text-gray-900 m-text-md-medium">
                {profileData.data?.branchName}
              </p>
            </div>
            <XB_Divider classes="mt-2 w-full" />
            <div
              className="flex mt-2 logout_btn cursor-pointer"
              onClick={() => {
                setOpenConfirmModal(true);
                setShowProfileModal(false);
              }}
            >
              <Icon icon="logout" color="none" />
              <h2 className="m-text-lg-medium ml-3 text-red-500">
                {t('logout')}
              </h2>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
