import { useAppSelector } from '@core-stores';

export function useCif() {
  const profileDataRdx = useAppSelector((state) => state?.profile);
  const { entityCif, userCif } =
    profileDataRdx.data?.merchants[profileDataRdx.data.activeMerchantId] ?? {};
  return {
    entityCif,
    userCif,
  };
}
