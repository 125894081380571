import moment from 'moment';

import { inputLength, RegEx } from '@core-constants';

export function convertDateWithOffset(
  date: string,
  dateFormat: string,
  offset: any
): string {
  return moment
    .utc(date, dateFormat)
    .utcOffset(offset)
    .format('YYYY-MM-DDTHH:mm:ss');
}

export function convertAppDateFormat(
  serverDate: string,
  serverDateFormat: string,
  format: string
): string {
  return moment.utc(serverDate, serverDateFormat).format(format);
}

export function msToTime(duration: number) {
  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  return { hours, minutes, seconds };
}

export function getTodayInFormat(format: string) {
  const date = moment();
  return date.format(format);
}

export function getMonth(format: string) {
  const monthNumber = format.substring(5, 7);
  return moment(monthNumber, 'MM').format('MMMM');
}

/**
 * @author Sunil-Jangid-323361
 * @description return age in number ex. 10
 * @param birthDateString  accept date object as string format: YYYY-MM-DD
 * @returns number
 */
export function getAge(birthDateString: string) {
  const today = moment();
  const birthDate = moment(birthDateString);
  return today.diff(birthDate, 'year');
}

/**
 * @description use only when we date object not other format allowed
 * @param date
 * @param format
 * @returns
 */
export function formatDateToStr(date: any, format: string) {
  return moment(date).format(format);
}

/**
 *
 * @param dateStr
 * @param dateFormat
 * @param convertFormat
 * @returns
 * @description get required format date from date string
 *
 */
export function getFormattedDate(
  dateStr: string | Date,
  dateFormat: string,
  convertFormat: string
) {
  if (!dateStr) {
    return;
  }

  try {
    return moment(dateStr, dateFormat).format(convertFormat);
  } catch (error) {
    return 'Invalid';
  }
}

/**
 *
 * @author Himanshu-Banate-296660
 * @param coolingPeriod in hours
 * @param updatedAtString in string
 * @returns boolean
 * @description Cooling period validation on addition of Payee
 *
 */
export function getCoolingPeriodDateValidation(
  updatedAtString: string,
  coolingPeriod: number
) {
  const now = moment();
  const updatedAt = moment(updatedAtString);
  const duration = now.diff(updatedAt, 'minutes');
  if (duration < coolingPeriod) {
    return true;
  } else {
    return false;
  }
}

/**
 *
 * @author Harshita-340789
 * @param event in React.BaseSyntheticEvent
 * @returns string
 * @description Card Expiry date format (MM/YY) on input.
 *
 */

export const formatExpiry = (e: any, value: any): string => {
  if (!value) {
    return '';
  }
  /* eslint-disable */
  const prevExpiry = value.split(' / ').join('/');
  if (!prevExpiry) return '';
  let expiry: any = prevExpiry;

  if (RegEx.expiryRegex.test(expiry)) {
    expiry = `0${expiry}`;
  }

  if (prevExpiry.length === 2 && +prevExpiry > inputLength.YEAR_MONTH_COUNT) {
    const [head, ...tail]: [string, string] = prevExpiry;
    expiry = `0${head}/${tail.join('')}`;
  }

  if (/^1[/-]$/.test(expiry)) {
    return `01 / `;
  }

  expiry = expiry.match(/(\d{1,2})/g) || [];
  if (expiry.length === 1) {
    if (prevExpiry.includes('/') && e.keyCode !== 8) {
      return expiry[0].toString();
    }
    if (/\d{2}/.test(expiry) && e.keyCode !== 8) {
      return `${expiry[0]} / `;
    }
  }
  if (expiry.length > 2) {
    const [, month, year] = expiry.join('').match(/^(\d{2})(\d{2})$/) || [];
    return [month, year].join(' / ').toString();
  }
  return expiry.join(' / ').toString();
};
export const getDateConditionally = (date) => {
  return date ? moment(date).format('DD MMM YYYY, h:mm A') : '-';
};

export const getLocalDateTime = (date) => {
  if (!date) {
    return;
  }
  try {
    return moment
      .utc(date, 'YYYY-MM-DD:HH:mm:ss')
      .local()
      .format('DD MMM YYYY h:mm A');
  } catch (error) {
    return 'Invalid';
  }
};
