import { EndPoints, Service } from '@core-services';

const { REACT_APP_FIREBASE_IDENTIFIER } = process.env;

export const verifyPan = async (
  dataObject: {
    mobileNumber: string;
    panNumber: string;
  },
  value
) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.verifyPAN(value).verifyPan,
    method: 'POST',
    url: '',
    obj: dataObject,
  };
  const response = await Service.send(reqObj, { appIdentifier: 'WEB_SO' });
  return response.data;
};

export const saveBusinessDetails = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.setBusinessDtl,
    method: 'POST',
    url: '',
    obj,
  };
  const resp = await Service.send(reqObj);
  return resp.data;
};

export const saveAdditionalBusinessDtl = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.setAdditionalBusinessDtl,
    method: 'POST',
    url: '',
    obj,
  };
  const resp = await Service.send(reqObj);
  return resp.data;
};

export const preserveAdditionalDtl = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.setAdditionPersonalDtl,
    method: 'POST',
    url: '',
    obj,
  };
  const resp = await Service.send(reqObj);
  return resp.data;
};

export const preserveDob = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.preserveDob,
    method: 'POST',
    url: '',
    obj,
  };
  const resp = await Service.send(reqObj);
  return resp.data;
};

export const saveSignAndShopImages = async (data) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.preserveSignAndShopImage,
    method: 'POST',
    url: '',
    obj: data,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const preserveCIFdetails = async (data) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.cifDetails,
    method: 'POST',
    url: '',
    obj: data,
  };
  const headers = {
    appIdentifier: `WEB_${REACT_APP_FIREBASE_IDENTIFIER}`,
  };
  const response = await Service.send(reqObj, headers);
  return response.data;
};

export const settlementData = async (data) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.settlementData,
    method: 'POST',
    url: '',
    obj: data,
  };
  const resp = await Service.send(reqObj);
  return resp.data;
};

export const fetchSummary = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.getSummary,
    method: 'POST',
    url: '',
    obj,
  };
  const resp = await Service.send(reqObj);
  return resp.data;
};

export const cifCreation = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.cifCreation,
    method: 'POST',
    url: '',
    obj,
  };
  const resp = await Service.send(reqObj);
  return resp.data;
};
export const cbsCall = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.cbsCall,
    method: 'POST',
    url: '',
    obj,
  };
  const resp = await Service.send(reqObj);
  return resp.data;
};
export const sendEmailNotifications = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.sendEmailNotification,
    method: 'POST',
    url: '',
    obj,
  };
  const resp = await Service.send(reqObj, { appIdentifier: 'WEB_SO' });

  return resp.data;
};

export const getSuggestVpa = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.suggestVpa,
    method: 'POST',
    url: '',
    obj,
  };
  const resp = await Service.send(reqObj);
  return resp.data;
};

export const saveUserVpa = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.createVpa,
    method: 'POST',
    url: '',
    obj,
  };
  const resp = await Service.send(reqObj);
  return resp.data;
};

export const getVpaList = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.listVpa,
    method: 'POST',
    url: '',
    obj,
  };
  const resp = await Service.send(reqObj);
  return resp.data;
};

export const deleteVpa = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.deleteVpa,
    method: 'POST',
    url: '',
    obj,
  };
  const resp = await Service.send(reqObj);
  return resp.data;
};

export const savePoeDetails = async (dataObject) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.preservePoeDetails,
    method: 'POST',
    url: '',
    obj: dataObject,
  };
  const response = await Service.send(reqObj, { appIdentifier: 'WEB_SO' });
  return response.data;
};
export const getSavedPoeDetails = async (dataObject) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.fetchSavedPoeDetails,
    method: 'POST',
    url: '',
    obj: dataObject,
  };
  const response = await Service.send(reqObj, { appIdentifier: 'WEB_SO' });
  return response.data;
};
export const poeNameMatch = async (dataObject, header) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.nameMatchPoe,
    method: 'POST',
    url: '',
    obj: dataObject,
  };
  const response = await Service.send(reqObj, { 'Match-Operand': header });
  return response.data;
};

export const preserveEntityAddress = async (data) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.preserveEntityAddress,
    method: 'POST',
    url: '',
    obj: data,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const searchGstDocs = async (data) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.searchGstDocs,
    method: 'POST',
    url: '',
    obj: data,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const fetchGstDetails = async (data) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.fetchGstDetails,
    method: 'POST',
    url: '',
    obj: data,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const validateResumeJourney = async (data) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.validateResumeV1,
    method: 'POST',
    url: '',
    obj: data,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const validateJourneyStatus = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.validateJourneyStatus,
    method: 'POST',
    url: '',
    obj,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const pollAPI = async (data) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.pollAPI,
    method: 'POST',
    url: '',
    obj: data,
  };
  const response = await Service.send(reqObj);
  return response.data;
};
