import { AU_Linkcard, QuickLinksDataProps } from '@core-components/molecules';

import './AU_QuickLinks.style.scss';

interface QuickLinksProps {
  cardData?: QuickLinksDataProps[];
  data?: QuickLinksDataProps[];
  title?: string;
  extraData?: React.JSX.Element;
}

export const AU_QuickLinks = ({
  cardData,
  data,
  title,
  extraData,
}: QuickLinksProps) => {
  return (
    <div className="casa-links-container w-full">
      <div className="account-summary-quick-link-container m-text-lg-bold flex flex-col px-4 py-4 gap-3 rounded-xl">
        {title && <h2 className="font-semibold">{title}</h2>}
        {extraData ?? null}
        {data?.map((item, index: number) => {
          return (
            <div key={index}>
              <AU_Linkcard data={item} />
            </div>
          );
        })}

        {cardData?.map((ele, index: number) => {
          return (
            <div key={index} className="hover:bg-orange-500">
              <AU_Linkcard data={ele} />
            </div>
          );
        })}
        {/* <XB_Divider />
        <img src={QuickLinkOffer} alt=""/>
        <img src={QuickLinkOffer2} alt=""/> */}
      </div>
    </div>
  );
};
