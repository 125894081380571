import { createSlice } from '@reduxjs/toolkit';

import { MRLimitDsbGetResponse } from '../../models/limit-management-mr.model';
import { merchantLimitManagentThunk } from '../thunks/limit-management-mr.thunk';

const initialState: MRLimitDsbGetResponse = {
  status: undefined,
  loading: false,
  successfulResponse: undefined,
  error: undefined,
  data: undefined,
};

const marchantLimitManagementSlice = createSlice({
  name: 'Merchant Limit Mangement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      merchantLimitManagentThunk.fulfilled,
      (state, { payload }) => {
        ({
          status: state.status,
          error: state.error,
          successfulResponse: state.successfulResponse,
          data: state.data,
          loading: state.loading,
        } = payload);
      }
    );
    builder.addCase(merchantLimitManagentThunk.pending, (state) => {
      state.status = undefined;
      state.successfulResponse = undefined;
      state.data = undefined;
      state.error = undefined;
      state.loading = true;
    });
    builder.addCase(
      merchantLimitManagentThunk.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = undefined;
        state.data = undefined;
        state.loading = false;
      }
    );
  },
});

export default marchantLimitManagementSlice.reducer;
