import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import {
  AU_AvatarProfile,
  AU_Badge,
  Icon,
  XB_Divider,
} from '@core-components/atoms';
import { AU_Sidenavlink } from '@core-components/molecules';
import { DASHBOARD_TYPE, navLink } from '@core-constants';
import { useAppSelector } from '@core-stores';

import EmergencyIcon from '../../../assets/images/emergency-icon-new.svg';

import './XB_SideNavbar.style.scss';

export interface SideNavbarProps {
  setOpenNavbar: (val: boolean) => void;
  isOpenSideNavbar: boolean;
}

export const XB_SideNavbar = ({
  isOpenSideNavbar,
  setOpenNavbar,
}: SideNavbarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dashboardType = useAppSelector((state) => state.dashboardTypeToggle);

  const navMenu =
    dashboardType.toggleType === DASHBOARD_TYPE.STORE
      ? navLink?.navMenuStore
      : navLink?.navMenuBanking;

  const classList: string[] = ['sidebar_wrapper'];
  isOpenSideNavbar
    ? classList.push('sidebar_wrapper_close')
    : classList.push('sidebar_wrapper_open');
  return (
    <div className="side_bar_container">
      <AU_Badge
        onClick={() => {
          setOpenNavbar(!isOpenSideNavbar);
        }}
        className={`
          ${isOpenSideNavbar ? 'side_bar_open_btn' : 'side_bar_close_btn'}
        `}
      >
        <Icon
          className="w-full"
          icon={isOpenSideNavbar ? 'pay-icon' : 'left-arrow-outlined'}
          width={18}
        />
      </AU_Badge>
      <div className={classList.join(' ')}>
        <div
          className={
            isOpenSideNavbar ? 'sidebar_list_closed' : 'sidebar_list_expanded'
          }
        >
          <AU_Sidenavlink
            label={t('home')}
            prefixIconName="home"
            isDropdown={false}
            labelPathname="/mr-dashboard"
            isExpandedSidebar={isOpenSideNavbar}
            navThemeType={dashboardType.toggleType}
          />
          {navMenu.map((ele, index) => {
            return (
              <div key={index}>
                <AU_Sidenavlink
                  label={ele.label}
                  prefixIconName={ele.prefixIconName}
                  suffixIconName={ele?.suffixIconName}
                  optionMenu={ele?.optionMenu}
                  isDropdown={ele.isDropdown}
                  rbac={ele?.rbac}
                  labelPathname={ele.labelPathname}
                  isExpandedSidebar={isOpenSideNavbar}
                  navThemeType={DASHBOARD_TYPE.BANK}
                  openSidebar={setOpenNavbar}
                />
              </div>
            );
          })}
        </div>

        <div className="navbar_assistance_container">
          <div
            className={
              !isOpenSideNavbar
                ? 'navbar_assistance_container_box'
                : 'navbar_assistance_closed_container_box'
            }
          >
            <AU_AvatarProfile
              size="md"
              element={
                <img
                  src={EmergencyIcon}
                  width={24}
                  height={24}
                  className={!isOpenSideNavbar ? 'mr-3' : ''}
                  alt=""
                />
              }
            />
            {!isOpenSideNavbar && (
              <div
                onClick={() => navigate('/profile/support')}
                className="cursor-pointer"
              >
                <h1 className="m-text-md-medium text-neutral-800">
                  {t('emergencyAssistance')}
                </h1>
                <h2 className="m-text-sm-regular text-neutral-700">
                  {t('emergencyAssistDesc')}
                </h2>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const XB_SideNavbarMob = ({
  isOpenSideNavbar,
  setOpenNavbar,
}: SideNavbarProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const initialRender = useRef(true);
  const sidebarRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    }
  }, [location.pathname]);
  const dashboardType = useAppSelector((state) => state.dashboardTypeToggle);

  const navMenu =
    dashboardType.toggleType === DASHBOARD_TYPE.STORE
      ? navLink?.navMenuStore
      : navLink?.navMenuBanking;

  useEffect(() => {
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setOpenNavbar(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className="sidebar_wrapper_overlay">
      <div
        className="sidebar_wrapper_open_mob sidebar_wrapper"
        ref={sidebarRef}
      >
        <div
          className="absolute right-8 top-8 cursor-pointer"
          onClick={() => {
            setOpenNavbar(!isOpenSideNavbar);
          }}
        >
          <Icon icon="cross" />
        </div>
        <XB_Divider size="normal" />
        <AU_Sidenavlink
          label={t('home')}
          prefixIconName="home"
          isDropdown={false}
          labelPathname="/mr-dashboard"
          isExpandedSidebar={false}
          navThemeType={dashboardType.toggleType}
          openSidebar={setOpenNavbar}
        />

        {navMenu.map((ele, index) => {
          return (
            <div key={index}>
              <AU_Sidenavlink
                label={ele.label}
                prefixIconName={ele.prefixIconName}
                suffixIconName={ele?.suffixIconName}
                optionMenu={ele?.optionMenu}
                isDropdown={ele.isDropdown}
                labelPathname={ele.labelPathname}
                rbac={ele?.rbac}
                isExpandedSidebar={false}
                navThemeType={DASHBOARD_TYPE.BANK}
                openSidebar={setOpenNavbar}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
