import { useTranslation } from 'react-i18next';

import { getColor } from 'core-utils';

import { ButtonProps, XB_Button } from '@core-components/atoms';

import './AU_BannerSlider.style.scss';

interface BannerSliderProps {
  key: string;
  bgImgUrl: string;
  title: string;
  subtitle: string;
  offerBackgroundColor?: string;
  offerHeadingColor?: string;
  offerSubheadingColor?: string;
  primaryBtnProps: ButtonProps;
  secondaryLink?: string;
  secondaryLinkColor?: string;
  secondaryLinkText?: string;
  target?: string;
  otherLinkProps?: any;
}

export const AU_BannerSlider = ({
  key,
  bgImgUrl,
  title = '',
  subtitle = '',
  offerBackgroundColor = '',
  offerHeadingColor = '#ffffff',
  offerSubheadingColor = '#ffffff',
  primaryBtnProps,
  secondaryLink,
  secondaryLinkColor = 'text-white m-text-xs-medium',
  secondaryLinkText,
  target,
  otherLinkProps,
}: BannerSliderProps) => {
  const { t } = useTranslation();

  return (
    <div
      key={key}
      style={{ backgroundColor: getColor(offerBackgroundColor) }}
      className={`banner-slider ${
        !secondaryLinkText ? 'cursor-pointer' : 'cursor-default'
      }`}
      onClick={
        !secondaryLinkText
          ? primaryBtnProps.onClick
          : () => {
              // This is intentional
            }
      }
    >
      {bgImgUrl && (
        <img
          width={'100%'}
          src={bgImgUrl ?? ''}
          className="rounded-[0.564rem] absolute h-full w-full"
          alt=""
        />
      )}
      <div className={`flex flex-col w-4/6 h-full z-50 relative p-2`}>
        <span
          style={{ color: getColor(offerHeadingColor) }}
          className={`text-left title-container m-text-md-bold text-white`}
        >
          {t(title)}
        </span>
        <span
          style={{ color: getColor(offerSubheadingColor) }}
          className={`text-left mt-1 subtitle-container m-text-xs-regular text-white`}
        >
          {t(subtitle)}
        </span>
        <div className="mt-1 absolute bottom-0 mb-2 flex flex-row">
          {primaryBtnProps.children && (
            <XB_Button
              classes="px-2 py-1 rounded m-text-xs-medium"
              backgroundColor={getColor(primaryBtnProps.backgroundColor ?? '')}
              {...primaryBtnProps}
            >
              {primaryBtnProps.children
                ? t(primaryBtnProps.children as string)
                : ''}
            </XB_Button>
          )}
          {secondaryLinkText && (
            <a
              className={`underline self-end mb-1 ml-2 m-text-xs-medium cursor-pointer`}
              style={{ color: getColor(secondaryLinkColor) }}
              href={secondaryLink}
              target={target}
              {...otherLinkProps}
            >
              {secondaryLinkText}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
